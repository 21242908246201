import React from 'react'
import axios from 'axios'
import qs from 'qs'
import moment from 'moment'

import { withRouter } from 'react-router-dom'
import { core, campaign_price } from '../../../config.js'

import Sidebar from '../sidebar.jsx'

class App extends React.Component {

	constructor( ) {

		super( )
		this.state = {

			data: null,
			locations: [ ],
			loading: true,

			secret: localStorage.getItem('authkey')

		}

	}

	componentDidMount( ) {

		axios.post(core, qs.stringify({

            businessCampaign: true,
            campaign: this.props.match.params.campaign,
            secret: this.state.secret

        })).then((response) => {

            if( response.data[0] === 'success' )
            	this.setState({ data: response.data[1] }, ( ) => {

            		axios.post(core, qs.stringify({

			            businessCampaignLocations: true,
			            campaign: this.props.match.params.campaign,
			            secret: this.state.secret

			        })).then((response) => {

			            if( response.data[0] === 'success' )
			            	this.setState({ locations: response.data[1], loading: false })

			            else
            				this.props.history.push('/404')


			        }).catch((error) => console.log(error))

            	})

            else
            	this.props.history.push('/404')

        }).catch((error) => console.log(error))

	}

	renderTime = ( time ) => {

		let times = time.split(' - ')

		let time1 = times[0].length === 4 ? `0${times[0]}` : times[0]
		let time2 = times[1].length === 4 ? `0${times[1]}` : times[1]

		return `${time1} - ${time2}`

	}

	renderDays = ( days ) => {

		let a_days = days.split(',')

		return `${moment(a_days[0], 'DD/MM/YYYY').format('MMM DD, YYYY')} - ${moment(a_days[a_days.length - 1], 'DD/MM/YYYY').format('MMM DD, YYYY')}`

	}

	render( ) {

		if( this.state.loading ) return null

		return (
			<div className="business">
				<Sidebar />

				<div className="page-content business-campaigns">

					<div className="page-breadcrumb" onClick={( ) => this.props.history.push('/business/campaigns')}>
						<img src="/landing/assets/images/back.svg" alt="" />
						<span>Annoncering</span>
					</div>

					<div className="row">

						<div className="col-8">

							<div className="campaign-details-card">

								<div className="title">{this.state.data.c_title}</div>
								<div className="description">{this.state.data.c_description}</div>

								<div className="d-flex align-items-cente dates">
									<img src="/landing/assets/images/campaign-calendar.svg" alt="" />
									<span>{this.renderDays(this.state.data.c_days)}</span>
								</div>

								<div className="d-flex align-items-cente time">
									<img src="/landing/assets/images/campaign-time.svg" alt="" />
									<span>{this.renderTime(this.state.data.c_time)}</span>
								</div>

								<div className="d-flex align-items-center total">
									<span>Total:</span>
									<span>{campaign_price * this.state.data.c_days.split(',').length * this.state.data.c_locations.split(',').length} kr.</span>
								</div>

							</div>

							<table className="locations-table">
							    <thead className="d-none">
							        <tr>
							            <th>ID</th>
							            <th>Placeringer</th>
							            <th>Kategori</th>
							            <th>Underkategori</th>
							            <th>By</th>
							        </tr>
							    </thead>
							    <tbody>
							    	{this.state.locations.map((node, i) => (
								        <tr key={`location${i}`}>
								            <td>{i + 1}</td>
								            <td>
								            	<span>{node.l_name}</span>
								            	<span>{node.l_address}</span>
								            </td>
								            <td>
								            	<span>{node.c_name}</span>
								            </td>
								            <td>
								            	<span>{node.sc_name}</span>
								            </td>
								            <td>
								            	<span>{node.l_city}</span>
								            </td>
								        </tr>
								    ))}
							    </tbody>
							</table>

						</div>

					</div>

				</div>
			</div>
		)

	}

}

export default withRouter(App)
