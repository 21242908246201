import React from 'react'
import axios from 'axios'
import qs from 'qs'
import moment from 'moment'

import { withRouter } from 'react-router-dom'
import { loadStripe } from '@stripe/stripe-js'
import { CardElement, Elements, ElementsConsumer } from '@stripe/react-stripe-js'

import DayPicker, { DateUtils } from 'react-day-picker'
import { core, campaign_price, toastError, stripe_api } from '../../../config.js'

import Sidebar from '../sidebar.jsx'
import 'react-day-picker/lib/style.css'

declare var jQuery: any

const stripePromise = loadStripe(stripe_api);

class App extends React.Component {

	constructor( ) {

		super( )
		this.state = {

			data: [ ],
			loading: true,

			days: [ ],
			hour1: '',
			hour2: '',
			title: '',
			description: '',
			paymentMethod: null,
			buttonLoading: false,

			modal: false,
			modalLoading: false,

			secret: localStorage.getItem('authkey')

		}

		this.dayClick = this.dayClick.bind(this)

	}

	componentDidMount( ) {

		axios.post(core, qs.stringify({

            businessLocationsByIds: true,
            ids: this.props.match.params.ids,
            secret: this.state.secret

        })).then((response) => {

            if( response.data[0] === 'success' ) {

            	this.setState({ data: response.data[1] }, ( ) => {

            		axios.post(core, qs.stringify({

			            businessPaymentMethod: true,
			            secret: this.state.secret

			        })).then((response) => {

			            if( response.data[0] === 'success' ) {

			            	this.setState({ paymentMethod: response.data[1], loading: false }, ( ) => {

			            		jQuery('body').on('change paste keyup', '.error', function( ) {

						            jQuery(this).removeClass('error')
						            jQuery(this).parent().removeClass('error')

						        })

			            	})

			            }

			        }).catch((error) => console.log(error))

            	})

            }

        }).catch((error) => console.log(error))

	}

	dayClick(day, { selected }) {

		const { days } = this.state

	    if ( selected ) {

	      	const selectedIndex = days.findIndex(selectedDay =>
	        	DateUtils.isSameDay(selectedDay, day)
	      	)

	      days.splice(selectedIndex, 1)
	    }

	    else
	    	days.push(day)

	    this.setState({ days }, ( ) => jQuery('.datepicker-card').removeClass('error'))

	}

	renderDay(day) {

		const date = day.getDate()

	  	return (
	    	<div>
	      		{date}
	    	</div>
	  	)
	}

	submit = ( ) => {

		let die = false

		if( !this.state.hour1 ) {

			jQuery('#select-hour1').parents('.f-input-group').addClass('error')
			die = true

		}

		if( !this.state.hour2 ) {

			jQuery('#select-hour2').parents('.f-input-group').addClass('error')
			die = true

		}

		if( !this.state.title.length ) {

			jQuery('#input-title').parent().addClass('error')
			die = true

		}

		if( !this.state.description.length || this.state.description.length > 800 ) {

			jQuery('#textarea-description').parent().addClass('error')
			die = true

		}

		if( !this.state.days.length ) {

			jQuery('.datepicker-card').addClass('error')
			die = true

		}

		if( die ) return false

		this.setState({ buttonLoading: true }, ( ) => {

			axios.post(core, qs.stringify({

	            businessPayCampaign: true,
	            locations: this.state.data.length,
	            days: this.state.days.join(','),
	            secret: this.state.secret

	        })).then((response) => {

				if( response.data[0] === 'success' )
					this.finish( )
				else
					this.setState({ buttonLoading: false }, ( ) => toastError('Please try again later.'))


	        }).catch((error) => console.log(error))

		})


	}

	finish = ( ) => {

		let fdays = [ ]
		let days = this.state.days

		// eslint-disable-next-line
		days.map((node) => {

			fdays.push(moment(node).format('DD/MM/YYYY'))

		})

		axios.post(core, qs.stringify({

            businessAddCampaign: true,
            locations: this.props.match.params.ids,
            time: `${this.state.hour1} - ${this.state.hour2}`,
            title: this.state.title,
            description: this.state.description,
            days: fdays.join(','),
            secret: this.state.secret

        })).then((response) => {

			if( response.data[0] === 'success' )
            	this.setState({ buttonLoading: false }, ( ) => this.props.history.push('/business/campaigns'))
           else
            	this.setState({ buttonLoading: false }, ( ) => toastError('Please try again later.'))

        }).catch((error) => console.log(error))


	}

	modal = ( state ) => {

		this.setState({ modal: state })

	}

	modalLoading = ( state ) => {

		this.setState({ modalLoading: state })

	}

	updatePayment = ( state ) => {

		this.setState({ paymentMethod: state })

	}

	render( ) {

		if( this.state.loading ) return null

		const total = campaign_price * this.state.days.length * this.props.match.params.ids.split(',').length

		return (
			<div className="business business-campaigns">
				<Sidebar />

				<div className="page-content">

					<div className="page-breadcrumb mb-0" onClick={( ) => this.props.history.push('/business/add-campaign')}>
						<img src="/landing/assets/images/back.svg" alt="" />
						<span>Vælg placeringer</span>
					</div>

					<div className="page-title mt-2">Skriv detaljer for din annoncering</div>

					<div className="row">

						<div className="col-8">

							<table className="locations-table">
							    <thead>
							        <tr>
							            <th>ID</th>
							            <th>Placering</th>
							            <th>Kategori</th>
							            <th>Underkategori</th>
							            <th>By</th>
							        </tr>
							    </thead>
							    <tbody>
							    	{this.state.data.map((node, i) => (
								        <tr key={`location${i}`}>
								            <td>{i + 1}</td>
								            <td>
								            	<span>{node.l_name}</span>
								            	<span>{node.l_address}</span>
								            </td>
								            <td>
								            	<span>{node.c_name}</span>
								            </td>
								            <td>
								            	<span>{node.sc_name}</span>
								            </td>
								            <td>
								            	<span>{node.l_city}</span>
								            </td>
								        </tr>
								    ))}
							    </tbody>
							</table>

							<div className="setup-card">

								<div className="row input-wrapper align-items-center">
									<div className="col-4">

										<div className="d-flex align-items-start">

											<img src="/landing/assets/images/time-interval.svg" alt="" />

											<div className="d-flex flex-column">
												<span>Tidsinterval</span>
												<span>Hvornår skal din annonce vises?</span>
											</div>

										</div>

									</div>

									<div className="col-8">

										<div className="inputs-form w-100">

											<div className="row">

												<div className="col-6">

													<div className="f-input-group">
														<div className="position-relative">
															<select className="input-select" id="select-hour1" onChange={( e ) => this.setState({ hour1: e.target.value }, ( ) => jQuery('#select-hour1').parents('.f-input-group').removeClass('error'))}>
																<option value="" disabled selected hidden>Vælg</option>
																<option value="00:00">00:00</option>
																<option value="00:30">00:30</option>
																<option value="01:00">01:00</option>
																<option value="01:30">01:30</option>
																<option value="02:00">02:00</option>
																<option value="02:30">02:30</option>
																<option value="03:00">03:00</option>
																<option value="03:30">03:30</option>
																<option value="04:00">04:00</option>
																<option value="04:30">04:30</option>
																<option value="05:00">05:00</option>
																<option value="05:30">05:30</option>
																<option value="06:00">06:00</option>
																<option value="06:30">06:30</option>
																<option value="07:00">07:00</option>
																<option value="07:30">07:30</option>
																<option value="08:00">08:00</option>
																<option value="08:30">08:30</option>
																<option value="09:00">09:00</option>
																<option value="09:30">09:30</option>
																<option value="10:00">10:00</option>
																<option value="10:30">10:30</option>
																<option value="11:00">11:00</option>
																<option value="11:30">11:30</option>
																<option value="12:00">12:00</option>
																<option value="12:30">12:30</option>
																<option value="13:00">13:00</option>
																<option value="13:30">13:30</option>
																<option value="14:00">14:00</option>
																<option value="14:30">14:30</option>
																<option value="15:00">15:00</option>
																<option value="15:30">15:30</option>
																<option value="16:00">16:00</option>
																<option value="16:30">16:30</option>
																<option value="17:00">17:00</option>
																<option value="17:30">17:30</option>
																<option value="18:00">18:00</option>
																<option value="18:30">18:30</option>
																<option value="19:00">19:00</option>
																<option value="19:30">19:30</option>
																<option value="20:00">20:00</option>
																<option value="20:30">20:30</option>
																<option value="21:00">21:00</option>
																<option value="21:30">21:30</option>
																<option value="22:00">22:00</option>
																<option value="22:30">22:30</option>
																<option value="23:00">23:00</option>
																<option value="23:30">23:30</option>
															</select>
														</div>
													</div>

												</div>

												<div className="col-6">

													<div className="f-input-group">
														<div className="position-relative">
															<select className="input-select" id="select-hour2" onChange={( e ) => this.setState({ hour2: e.target.value }, ( ) => jQuery('#select-hour2').parents('.f-input-group').removeClass('error'))}>
																<option value="" disabled selected hidden>Vælg</option>
																<option value="00:00">00:00</option>
																<option value="00:30">00:30</option>
																<option value="01:00">01:00</option>
																<option value="01:30">01:30</option>
																<option value="02:00">02:00</option>
																<option value="02:30">02:30</option>
																<option value="03:00">03:00</option>
																<option value="03:30">03:30</option>
																<option value="04:00">04:00</option>
																<option value="04:30">04:30</option>
																<option value="05:00">05:00</option>
																<option value="05:30">05:30</option>
																<option value="06:00">06:00</option>
																<option value="06:30">06:30</option>
																<option value="07:00">07:00</option>
																<option value="07:30">07:30</option>
																<option value="08:00">08:00</option>
																<option value="08:30">08:30</option>
																<option value="09:00">09:00</option>
																<option value="09:30">09:30</option>
																<option value="10:00">10:00</option>
																<option value="10:30">10:30</option>
																<option value="11:00">11:00</option>
																<option value="11:30">11:30</option>
																<option value="12:00">12:00</option>
																<option value="12:30">12:30</option>
																<option value="13:00">13:00</option>
																<option value="13:30">13:30</option>
																<option value="14:00">14:00</option>
																<option value="14:30">14:30</option>
																<option value="15:00">15:00</option>
																<option value="15:30">15:30</option>
																<option value="16:00">16:00</option>
																<option value="16:30">16:30</option>
																<option value="17:00">17:00</option>
																<option value="17:30">17:30</option>
																<option value="18:00">18:00</option>
																<option value="18:30">18:30</option>
																<option value="19:00">19:00</option>
																<option value="19:30">19:30</option>
																<option value="20:00">20:00</option>
																<option value="20:30">20:30</option>
																<option value="21:00">21:00</option>
																<option value="21:30">21:30</option>
																<option value="22:00">22:00</option>
																<option value="22:30">22:30</option>
																<option value="23:00">23:00</option>
																<option value="23:30">23:30</option>
															</select>
														</div>
													</div>

												</div>

											</div>

										</div>

									</div>
								</div>

								<div className="row input-wrapper align-items-center">
									<div className="col-4">

										<div className="d-flex align-items-start">

											<img src="/landing/assets/images/campaign-title.svg" alt="" />

											<div className="d-flex flex-column">
												<span>Overskrift</span>
												<span>Promover dine produkter eller services</span>
											</div>

										</div>

									</div>

									<div className="col-8">

										<div className="inputs-form w-100">

											<div className="f-input-group">
												<input type="text" className="input-text" placeholder="Type here" id="input-title" onChange={(e) => this.setState({ title: e.target.value })} />
											</div>

										</div>

									</div>
								</div>

								<div className="row input-wrapper">
									<div className="col-4">

										<div className="d-flex align-items-start">

											<img src="/landing/assets/images/addescription.svg" alt="" />

											<div className="d-flex flex-column">
												<span>Beskrivelse</span>
												<span>Something more that brings value</span>
											</div>

										</div>

									</div>

									<div className="col-8">

										<div className="inputs-form w-100">

											<div className="f-input-group">
												<textarea className="input-text" placeholder="Skriv her" id="textarea-description" rows="4" cols="50" onChange={(e) => this.setState({ description: e.target.value }, ( ) => jQuery('#textarea-description').parent().removeClass('error') )}></textarea>
											</div>

										</div>

									</div>
								</div>

							</div>

							<div className="total-card">

								<div>
									<div>
										<span className="total">Total:</span>
										<span className="price">{total} kr.</span>
									</div>

									<div className="payment-info">

										<span>Betalingsmetode:</span>
										<span>Kort der ender på {this.state.paymentMethod}</span>
										<span onClick={( ) => this.setState({ modal: true })}>Opdater info</span>

									</div>
								</div>

								<button className={this.state.buttonLoading ? 'btn btn-primary transition-active disabled pe-none' : 'btn btn-primary transition-active' } onClick={( ) => this.submit( )}>{this.state.buttonLoading ? <div className="spinner-border spinner-border-sm"></div> : 'Fortsæt til betaling'}</button>

							</div>

						</div>

						<div className="col-4">

							<div className="datepicker-card">
								<DayPicker
									selectedDays={this.state.days}
          							onDayClick={this.dayClick}
          							renderDay={this.renderDay}
          							fromMonth={new Date()}
          							disabledDays={[{

							        	before: new Date()

							        }]}
								/>
							</div>

							<div className="totaldays-card">

								<img src="/landing/assets/images/totaldays.svg" alt="" />
								<span>{this.state.days.length}  valgt{this.state.days.length === 1 ? null : 'e'} dag{this.state.days.length === 1 ? null : 'e'}</span>

							</div>

						</div>

					</div>

				</div>

				{this.state.modal ? (

					<div className="stripe-modal animated fadeIn">

						{!this.state.modalLoading ? (

							<React.Fragment>

								<div className="s-title">Update payment method</div>

								<Elements stripe={stripePromise}>
									<InjectedCheckoutForm modal={this.modal} modalLoading={this.modalLoading} updatePayment={this.updatePayment} secret={this.state.secret} />
								</Elements>

							</React.Fragment>

						) : (

							<div className="animated fadeIn">
								<div className="spinner-border text-secondary" />
							</div>

						)}

					</div>

				) : null}
			</div>
		)

	}
}

class CheckoutForm extends React.Component {

	constructor( ) {

		super( )
		this.state = {

			name: ''

		}

	}

	handleSubmit = async (event) => {

		event.preventDefault( )

		const {stripe, elements} = this.props
		const { name } = this.state

		if( !name.length ) {

			jQuery('.stripe-input-name').addClass('error')
			return false

		}

		const {error, paymentMethod} = await stripe.createPaymentMethod({

			type: 'card',
			card: elements.getElement(CardElement),
			billing_details: {
				name
			}

		})

		if (!error) {

			this.props.modalLoading(true)

			axios.post(core, qs.stringify({

	            businessUpdatePaymentMethod: true,
	            paymentMethod: paymentMethod.id,
	            secret: this.props.secret

	        })).then((response) => {

	       		if( response.data[0] === 'success' ) {

	       			this.props.updatePayment(response.data[1])
	       			this.props.modal(false)
	       			this.props.modalLoading(false)

	       		}

	       		else
	       			this.props.modalLoading(false)

	        }).catch((error) => console.log(error))

		}

	}

	render( ) {

		return (

			<form onSubmit={this.handleSubmit} className="stripe-form">

				<input type="text" placeholder="Type cardholder name here.." className="stripe-input-name" onChange={( e ) => this.setState({ name: e.target.value })} />

				<CardElement
					options={{
						style: {
							base: {
								color: '#0D182F',
								fontFamily: '"hk_grotesksemibold", sans-serif',
								fontSize: '16px',
							 	'::placeholder': {
									color: '#aab7c4'
								}
							},
							invalid: {
								color: '#F2607B',
								iconColor: '#F2607B'
							}
						}
					}}
				/>

				<button type="submit" className="btn btn-primary">
					Update
				</button>
			</form>

		)

	}

}

const InjectedCheckoutForm = ( { modal, modalLoading, updatePayment, secret } ) => (

	<ElementsConsumer>

		{({stripe, elements}) => <CheckoutForm stripe={stripe} elements={elements} secret={secret} modal={modal} modalLoading={modalLoading} updatePayment={updatePayment} />}

	</ElementsConsumer>

)

export default withRouter(App)
