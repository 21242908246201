import React from 'react'
import { useLocation, useParams } from 'react-router-dom'

export default function App() {
	
	const location = useLocation( )
	const params = useParams( )

	return (
		<div className="sidebar">
			<img src="/landing/assets/images/business-logo.svg" alt="" />

			<div className="content">
				
				<div className="heading-title">To continue</div>
				<ul>
					<li className={location.pathname === '/business/register/step1/' + params.secret ? 'active pe-none' : 'pe-none'}>
						<svg width="24px" height="24px" viewBox="0 0 24 24">
						    <g id="Companies" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
						        <g id="Business-Finish-Step-1" className="fill-color" transform="translate(-120.000000, -223.000000)" fill="#0D182F">
						            <g id="part-2-copy" transform="translate(120.000000, 171.000000)">
						                <g id="Group-7" transform="translate(0.000000, 52.000000)">
						                    <path d="M12,2 C6.477,2 2,6.477 2,12 C2,14.571 2.97,16.916 4.565,18.688 C5.4,17.09 7.072,16 9,16 L15,16 C16.928,16 18.6,17.09 19.435,18.688 C21.03,16.916 22,14.571 22,12 C22,6.477 17.523,2 12,2 Z M17.863,20.102 C17.482,18.884 16.344,18 15,18 L9,18 C7.656,18 6.518,18.884 6.137,20.102 C7.784,21.296 9.809,22 12,22 C14.191,22 16.216,21.296 17.863,20.102 Z M0,12 C0,5.373 5.373,0 12,0 C18.627,0 24,5.373 24,12 C24,15.742 22.286,19.084 19.604,21.283 C17.535,22.98 14.885,24 12,24 C9.115,24 6.465,22.98 4.396,21.283 C1.714,19.084 0,15.742 0,12 Z M12,7 C10.329,7 8.982,8.347 8.982,10 C8.982,11.653 10.329,13 12,13 C13.671,13 15.018,11.653 15.018,10 C15.018,8.347 13.671,7 12,7 Z M6.982,10 C6.982,7.234 9.233,5 12,5 C14.767,5 17.018,7.234 17.018,10 C17.018,12.766 14.767,15 12,15 C9.233,15 6.982,12.766 6.982,10 L6.982,10 Z" id="Fill-941"></path>
						                </g>
						            </g>
						        </g>
						    </g>
						</svg>
						<span>Business profile</span>
					</li>
					<li className={location.pathname === '/business/register/step2/' + params.secret ? 'active pe-none' : 'pe-none'}>
						<svg width="24px" height="22px" viewBox="0 0 24 22">
						    <g id="Companies" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
						        <g id="Business-Finish-Step-1" className="fill-color" transform="translate(-120.000000, -275.000000)" fill="#FF0000">
						            <g id="part-2-copy" transform="translate(120.000000, 171.000000)">
						                <g id="Group-8" transform="translate(0.000000, 104.000000)">
						                    <path d="M12.587919,2.19075 C12.237919,1.93575 11.762919,1.93575 11.411919,2.19075 L4.99991904,6.85475 L4.99991904,18.52675 C4.99991904,19.07875 5.44791904,19.52675 5.99991904,19.52675 L7.99991904,19.52675 L7.99991904,13.52675 C7.99991904,11.86975 9.34291904,10.52675 10.999919,10.52675 L12.999919,10.52675 C14.656919,10.52675 15.999919,11.86975 15.999919,13.52675 L15.999919,19.52675 L17.999919,19.52675 C18.551919,19.52675 18.999919,19.07875 18.999919,18.52675 L18.999919,6.85475 L12.587919,2.19075 Z M20.999919,8.30875 L22.411919,9.33575 C22.857919,9.66075 23.483919,9.56175 23.808919,9.11475 C24.133919,8.66875 24.034919,8.04275 23.587919,7.71775 L13.764919,0.57375 C12.711919,-0.19125 11.287919,-0.19125 10.234919,0.57375 L0.411919038,7.71775 C-0.0350809624,8.04275 -0.133080962,8.66875 0.190919038,9.11475 C0.515919038,9.56175 1.14191904,9.66075 1.58791904,9.33575 L2.99991904,8.30875 L2.99991904,18.52675 C2.99991904,20.18375 4.34291904,21.52675 5.99991904,21.52675 L17.999919,21.52675 C19.656919,21.52675 20.999919,20.18375 20.999919,18.52675 L20.999919,8.30875 Z M13.999919,19.52675 L13.999919,13.52675 C13.999919,12.97475 13.551919,12.52675 12.999919,12.52675 L10.999919,12.52675 C10.447919,12.52675 9.99991904,12.97475 9.99991904,13.52675 L9.99991904,19.52675 L13.999919,19.52675 L13.999919,19.52675 Z" id="Fill-1184"></path>
						                </g>
						            </g>
						        </g>
						    </g>
						</svg>
						<span>Business locations</span>
					</li>
				</ul>

				{/*<div className="logout">Log out</div>*/}
			</div>
		</div>
	)
}