import React from 'react'
import axios from 'axios'
import qs from 'qs'
import moment from 'moment'

import { withRouter } from 'react-router-dom'
import { core, Swal, subscription_monthly } from '../../../config.js'
import Sidebar from '../sidebar.jsx'

class App extends React.Component {

	constructor( ) {

		super( )
		this.state = {

			data: null,
			subscription: null,
			schedule: null,
			loading: true,

			secret: localStorage.getItem('authkey')

		}

	}

	componentDidMount( ) {

		axios.post(core, qs.stringify({

            businessProfile: true,
            secret: this.state.secret

        })).then((response) => {

            if( response.data[0] === 'success' )
            	this.setState({ data: response.data[1] }, ( ) => this.getSubscription( ))

        }).catch((error) => console.log(error))

	}

	getSubscription = ( ) => {

		axios.post(core, qs.stringify({

	        businessSubscription: true,
	        secret: this.state.secret

	    })).then((response) => {

	        if( response.data[0] === 'success' )
	        	this.setState({ subscription: response.data[1], schedule: response.data[2], loading: false })

	    }).catch((error) => console.log(error))

	}

	delete = ( ) => {

		Swal( 'Slet konto', 'Kontoen bliver slettet permanent og kan ikke gendannes', 'danger', true, 'Slet' ).then((result) => {

            if( !result.value )
                return

            axios.post(core, qs.stringify({

	            businessDeleteAccount: true,
	            secret: this.state.secret

	        })).then((response) => {

	            if( response.data[0] === 'success' ) {

	            	localStorage.removeItem('authkey')
	            	window.location.href = 'https://danguiden.dk'

	            }

        	}).catch((error) => console.log(error))

        })

	}

	changePeriod = ( ) => {

		if( this.state.schedule === null ) {

			Swal( 'Skift periode', `After current plan is finished (${moment(this.state.subscription.current_period_end * 1000).format('DD/MM/YYYY')}), your new plan will renew ${this.state.subscription.plan.id === subscription_monthly ? 'yearly' : 'monthly'} for ${this.state.subscription.plan.id === subscription_monthly ? 510 : 50} kr (taxes included).`, 'warning', true, 'Change' ).then((result) => {

	            if( !result.value )
	                return

	            axios.post(core, qs.stringify({

		            businessChangePeriod: true,
		            plan: this.state.subscription.plan.id,
		            period: this.state.subscription.current_period_end,
		            secret: this.state.secret

		        })).then((response) => {

		            if( response.data[0] === 'success' )
		            	this.getSubscription( )

	        	}).catch((error) => console.log(error))

	        })

		}

		else {

			Swal( 'Undo changes', `Are you sure you want to undo the changes?`, 'warning', true, 'Undo changes' ).then((result) => {

	            if( !result.value )
	                return

	            axios.post(core, qs.stringify({

		            businessUndoPeriod: true,
		            secret: this.state.secret

		        })).then((response) => {

		            if( response.data[0] === 'success' )
		            	this.getSubscription( )

	        	}).catch((error) => console.log(error))

	        })

		}

	}

	cancel = ( ) => {

		Swal( 'Annuller abonnement', `Are you sure you want to cancel your subscription?`, 'warning', true, 'Annuller abonnement' ).then((result) => {

            if( !result.value )
                return

            axios.post(core, qs.stringify({

	            businessCancelSubscription: true,
	            secret: this.state.secret

	        })).then((response) => {

	            if( response.data[0] === 'success' )
	            	this.getSubscription( )

        	}).catch((error) => console.log(error))

        })

	}

	undoCancel = ( ) => {

		Swal( 'Undo changes', `Are you sure you want to undo the changes?`, 'warning', true, 'Undo changes' ).then((result) => {

            if( !result.value )
                return

            axios.post(core, qs.stringify({

	            businessUndoCancelSubscription: true,
	            secret: this.state.secret

	        })).then((response) => {

	            if( response.data[0] === 'success' )
	            	this.getSubscription( )

        	}).catch((error) => console.log(error))

        })

	}

	render( ) {

		if( this.state.loading ) return null

		return (
			<div className="business">
				<Sidebar />

				<div className="page-content business-location business-profile">

					<div className="page-title">Profil</div>

					<div className="row">
						<div className="col-4">

							<div className="custom-card">
								<img src={this.state.data.b_logo} alt="" />
								<div className="title">{this.state.data.b_name}</div>

								<ul>
									<li>
										<span>Adresse</span>
										<span>{this.state.data.b_address}</span>
									</li>
									<li>
										<span>By</span>
										<span>{this.state.data.b_city}</span>
									</li>
									<li>
										<span>Postnummer</span>
										<span>{this.state.data.b_zipcode}</span>
									</li>
									<li>
										<span>CVR</span>
										<span>{this.state.data.b_vat}</span>
									</li>
									<li>
										<span>Telefon</span>
										<span>{this.state.data.b_phone}</span>
									</li>
									<li>
										<span>Hjemmeside</span>
										<span style={{ cursor: 'pointer' }} onClick={( ) => window.open(this.state.data.b_website, '_blank')}>{this.state.data.b_website}</span>
									</li>
								</ul>

								<div className="contact-person">
									<span>Kontaktperson</span>
									<span>{this.state.data.b_contact}</span>
									<span>{this.state.data.b_contact_position}</span>
								</div>
							</div>

							<div className="subscription-card">
								<div className="title">{this.state.subscription.plan.id === subscription_monthly ? 'Månedlige' : 'Årligt'} Abonnement</div>

								{this.state.subscription.cancel_at_period_end && this.state.schedule === null ? (

									<div className="description">Din Danguiden-plan slutter {moment(this.state.subscription.current_period_end * 1000).format('DD/MM/YYYY')}, fortryd ændringer, hvis du vil fortsætte med din nuværende Danguiden-plan.</div>

								) : (

									this.state.schedule === null ? (

										<div className="description">Din Danguiden-plan fornyes den {moment(this.state.subscription.current_period_end * 1000).format('DD/MM/YYYY')} for {this.state.subscription.plan.amount / 100} kr. (inklusive moms).</div>

									) : (

										<div className="description">Din nuværende {this.state.subscription.plan.id === subscription_monthly ? 'Monthly' : 'Yearly'} Danguiden Plan vil være aktiv indtil {moment(this.state.subscription.current_period_end * 1000).format('DD/MM/YYYY')}, så din nye {this.state.schedule.phases[0].plans[0].plan === subscription_monthly ? 'Månedlige' : 'Årligt'} Danguiden Plan vil være aktiv indtil {moment(this.state.schedule.phases[0].end_date * 1000).format('DD/MM/YYYY')} for {this.state.schedule.phases[0].plans[0].plan === subscription_monthly ? 50 : 510} kr. (taxes included).</div>

									)

								)}

								{this.state.subscription.cancel_at_period_end && this.state.schedule === null ? (

									<div className="d-flex justify-content-between align-items-center subscription-buttons">
										<span className="btn btn-primary" onClick={( ) => this.undoCancel( )}>Fortryd</span>
									</div>

								) : (

									<div className="d-flex justify-content-between align-items-center subscription-buttons">
										<span className="btn btn-primary" onClick={( ) => this.changePeriod( )}>{this.state.schedule === null ? 'Skift periode' : 'Undo changes'}</span>
										<span onClick={( ) => this.cancel()}>Annuller abonnement</span>
									</div>

								)}


							</div>

						</div>
						<div className="col-4 quick-actions">
							<div className="title">MENU</div>
							<ul>
								<li onClick={( ) => this.props.history.push('/business/edit-profile') }>
									<svg width="23px" height="23px" viewBox="0 0 23 23">
									    <g id="Companies" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
									        <g id="Danguiden-Business---Locations-Details" className="fill-color" transform="translate(-1337.000000, -222.000000)" fill="#0D182F">
									            <g id="part-2" transform="translate(1335.000000, 171.000000)">
									                <g id="Group-7" transform="translate(2.000000, 51.000000)">
									                    <path d="M17.086,0.58575 C17.867,-0.19525 19.133,-0.19525 19.914,0.58575 L21.5,2.17175 C22.281,2.95275 22.281,4.21875 21.5,4.99975 L15,11.49975 C14.625,11.87475 14.116,12.08575 13.586,12.08575 L11,12.08575 C10.448,12.08575 10,11.63775 10,11.08575 L10,8.49975 C10,7.96975 10.211,7.46075 10.586,7.08575 L17.086,0.58575 Z M20.086,3.58575 L18.5,1.99975 L12,8.49975 L12,10.08575 L13.586,10.08575 L20.086,3.58575 Z M3,3.08575 C2.448,3.08575 2,3.53375 2,4.08575 L2,19.08575 C2,19.63775 2.448,20.08575 3,20.08575 L18,20.08575 C18.552,20.08575 19,19.63775 19,19.08575 L19,11.08575 C19,10.53375 19.448,10.08575 20,10.08575 C20.552,10.08575 21,10.53375 21,11.08575 L21,19.08575 C21,20.74275 19.657,22.08575 18,22.08575 L3,22.08575 C1.343,22.08575 -2.84217094e-14,20.74275 -2.84217094e-14,19.08575 L-2.84217094e-14,4.08575 C-2.84217094e-14,2.42875 1.343,1.08575 3,1.08575 L11,1.08575 C11.552,1.08575 12,1.53375 12,2.08575 C12,2.63775 11.552,3.08575 11,3.08575 L3,3.08575 L3,3.08575 Z" id="Fill-1154"></path>
									                </g>
									            </g>
									        </g>
									    </g>
									</svg>
									<span>Rediger oplysninger</span>
								</li>
								<li onClick={( ) => this.delete( )}>
									<svg width="20px" height="24px" viewBox="0 0 20 24">
									    <g id="Companies" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
									        <g id="Danguiden-Business---Locations-Details" className="fill-color" transform="translate(-1339.000000, -325.000000)" fill="#0D182F">
									            <g id="part-2" transform="translate(1335.000000, 171.000000)">
									                <g id="Group-9" transform="translate(4.000000, 154.000000)">
									                    <path d="M9,2 C8.448,2 8,2.448 8,3 L8,4 L12,4 L12,3 C12,2.448 11.552,2 11,2 L9,2 Z M14,4 L14,3 C14,1.343 12.657,0 11,0 L9,0 C7.343,0 6,1.343 6,3 L6,4 L1,4 C0.448,4 0,4.448 0,5 C0,5.552 0.448,6 1,6 L1.105,6 L2.808,21.331 C2.977,22.851 4.261,24 5.79,24 L14.21,24 C15.739,24 17.023,22.851 17.191,21.331 L18.895,6 L19,6 C19.552,6 20,5.552 20,5 C20,4.448 19.552,4 19,4 L14,4 Z M16.883,6 L3.117,6 L4.796,21.11 C4.853,21.617 5.281,22 5.79,22 L14.21,22 C14.719,22 15.147,21.617 15.204,21.11 L16.883,6 Z M8,9 C8.552,9 9,9.448 9,10 L9,18 C9,18.552 8.552,19 8,19 C7.448,19 7,18.552 7,18 L7,10 C7,9.448 7.448,9 8,9 Z M12,9 C12.552,9 13,9.448 13,10 L13,18 C13,18.552 12.552,19 12,19 C11.448,19 11,18.552 11,18 L11,10 C11,9.448 11.448,9 12,9 L12,9 Z" id="Fill-1166"></path>
									                </g>
									            </g>
									        </g>
									    </g>
									</svg>
									<span>Slet konto</span>
								</li>
							</ul>
						</div>
					</div>

				</div>
			</div>
		)

	}

}

export default withRouter(App)
