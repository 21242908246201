import React from 'react'
import axios from 'axios'
import moment from 'moment'

import { withRouter } from 'react-router-dom'
import { core } from '../../config.js'

import Sidebar from './sidebar.jsx'

declare var jQuery: any

class App extends React.Component {

	constructor() {

		super( )
		this.state = {

			categories: [ ],
			locations: [ ],

			secret: localStorage.getItem('authkey')

		}

	}

	componentDidMount( ) {

		axios.get(`${core}?getCategoriesAndSubcategories`).then((response) => {

			if( response.data[0] === 'success' )
				this.setState({ categories: response.data[1] }, ( ) => {

					jQuery('.checkboxes-wrapper i').click(function( ) {

						var obj = jQuery(this)
						var category = obj.parents('.checkboxes-category').next()

						category.slideToggle('fast', function( ) {

							if( !category.is(':visible') )
								obj.removeClass('fa-chevron-down').addClass('fa-chevron-up')
							else
								obj.removeClass('fa-chevron-up').addClass('fa-chevron-down')

						})

					})

				} )

		}).catch((error) => console.log(error))

		this.locations(0)

	}

	locations = ( subcategory ) => {

		axios.get(`${core}?getAdminLocations=${this.state.secret}&subcategory=${subcategory}`).then((response) => {

			if( response.data[0] === 'success' )
				this.setState({ locations: response.data[1] })

		}).catch((error) => console.log(error))

	}

	filterLocation = ( subcategory, element ) => {

		jQuery('.styled-checkbox').prop('checked', false)
		jQuery(`#${element}`).prop('checked', true)
		this.locations(subcategory)

	}

	render() {
		return (
			<div className="business">
				<Sidebar />

				<div className="page-content admin-locations-list">
					<div className="page-title">Viser {this.state.locations.length} {this.state.locations.length === 1 ? 'placeringer' : 'placeringer'}</div>

					<div className="row">
						<div className="col-3" style={{ marginTop: 10 }}>

							{this.state.categories.map((node, i) => (

								<div key={`category-${node.c_id}`} className="checkboxes-wrapper">
									<div className="checkboxes-category">
										<span>{node.c_name}</span>
										<i className="fas fa-chevron-down"></i>
									</div>

									<div className="checkboxes">
										<ul>
											{node.subcategories.split(',').map((snap, i2) => (

												<li key={snap + i2}>
													<div>
														<input className="styled-checkbox" id={`subcategory-checkbox-${i}-${i2}`} type="checkbox" onChange={( e ) => e.target.checked ? this.filterLocation(snap.split('#')[1], `subcategory-checkbox-${i}-${i2}`) : this.locations(0)} />
														<label htmlFor={`subcategory-checkbox-${i}-${i2}`} className="with-margin">{snap.split('#')[0]}</label>
													</div>
													<span>{snap.split('#')[2]}</span>
												</li>

											))}
										</ul>
									</div>
								</div>

							))}
						</div>
						<div className="col-9">
							<table className="locations-table-admin">
								<thead>
									<tr>
										<th>ID</th>
										<th>Ejer</th>
										<th>Placeringer</th>
										<th>Kategori</th>
										<th>Underkategori</th>
										<th>By</th>
										<th>Tilføjet den</th>
										<th></th>
									</tr>
								</thead>
								<tbody>
									{this.state.locations.map((node, i) => (
										<tr key={`location${i}`}>
											<td>{i + 1}</td>
											<td><img src={node.b_logo} alt="" /></td>
											<td>
												<span>{node.l_name}</span>
												<span>{node.l_address}</span>
											</td>
											<td>
												<span>{node.c_name}</span>
											</td>
											<td>
												<span>{node.sc_name}</span>
											</td>
											<td>
												<span>{node.l_city}</span>
											</td>
											<td>
												<span>{moment(node.l_date).format('MMM DD, YYYY')}</span>
											</td>
											<td>
												<span className="btn btn-primary" onClick={( ) => this.props.history.push(`/admin/view-location/${node.l_id}`)}>Se detaljer</span>
											</td>
										</tr>

									))}
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

export default withRouter(App)
