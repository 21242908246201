import React from 'react'
import {
	BrowserRouter as Router,
	Switch,
	Route
} from 'react-router-dom'

import axios from 'axios'
import qs from 'qs'

import { core, admin_secret } from './config.js'

import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import BusinessRegisterStep1 from './pages/business/register/step1.jsx'
import BusinessRegisterStep2 from './pages/business/register/step2.jsx'

import BusinessLogin from './pages/business/register/login.jsx'
import BusinessResetPassowrd from './pages/business/register/resetPassword.jsx'

import BusinessLocations from './pages/business/location/locations.jsx'
import BusinessViewLocation from './pages/business/location/viewLocation.jsx'
import BusinessAddLocation from './pages/business/location/addLocation.jsx'
import BusinessEditLocation from './pages/business/location/editLocation.jsx'
import BusinessCampaigns from './pages/business/campaign/campaigns.jsx'
import BusinessAddCampaign from './pages/business/campaign/addCampaign.jsx'
import BusinessAddCampaignSetup from './pages/business/campaign/addCampaignSetup.jsx'
import BusinessViewCampaign from './pages/business/campaign/viewCampaign.jsx'
import BusinessProfile from './pages/business/profile/profile.jsx'
import BusinessEditProfile from './pages/business/profile/editProfile.jsx'
import BusinessHistory from './pages/business/history.jsx'

import AdminLocations from './pages/admin/locations.jsx'
import AdminViewLocation from './pages/admin/viewLocation.jsx'
import AdminBusinesses from './pages/admin/businesses.jsx'
import AdminViewBusiness from './pages/admin/viewBusiness.jsx'

import Page404 from './pages/404.jsx'

class App extends React.Component {

	constructor( ) {

		super( )
		this.state = {

			secret: localStorage.getItem('authkey'),
			loading: true,

			logged: false

		}

	}

	componentDidMount( ) {

		axios.post(core, qs.stringify({

            existsBusiness: true,
            secret: this.state.secret

        })).then((response) => this.setState({ logged: response.data[0] === 'success' ? true : false, loading: false }))
        .catch((error) => console.log(error))

	}

	renderPage = ( page, logged, admin = false ) => {

		if( logged ) {

			if( this.state.logged ) {

				if( admin ) {

					if( this.state.secret === admin_secret )
						return page
					else
						return <Page404 />

				}

				else
					return page

			}

			return <Page404 />

		}

		return page
		
	}

	render( ) {

		if( this.state.loading ) return null

		return (
			<React.Fragment>
				<Router>
					<Switch>
						<Route path="/business/register/step1/:secret">
							{this.renderPage(BusinessRegisterStep1, false )}
						</Route>

						<Route path="/business/register/step2/:secret">
							{this.renderPage(BusinessRegisterStep2, false )}
						</Route>

						<Route exact path="/business/login/:secret">
							{this.renderPage(BusinessLogin, false )}
						</Route>

						<Route exact path="/business/reset-password/:hash">
							{this.renderPage(BusinessResetPassowrd, false )}
						</Route>

						<Route exact path="/business">
							{this.renderPage(BusinessLocations, true )}
						</Route>

						<Route path="/business/view-location/:location">
							{this.renderPage(BusinessViewLocation, true )}
						</Route>

						<Route path="/business/add-location">
							{this.renderPage(BusinessAddLocation, true )}
						</Route>

						<Route path="/business/edit-location/:location">
							{this.renderPage(BusinessEditLocation, true )}
						</Route>

						<Route path="/business/campaigns">
							{this.renderPage(BusinessCampaigns, true )}
						</Route>	

						<Route exact path="/business/add-campaign">
							{this.renderPage(BusinessAddCampaign, true )}
						</Route>	

						<Route path="/business/add-campaign/:ids">
							{this.renderPage(BusinessAddCampaignSetup, true )}
						</Route>

						<Route path="/business/view-campaign/:campaign">
							{this.renderPage(BusinessViewCampaign, true )}
						</Route>			

						<Route path="/business/profile">
							{this.renderPage(BusinessProfile, true )}
						</Route>

						<Route path="/business/edit-profile">
							{this.renderPage(BusinessEditProfile, true )}
						</Route>

						<Route path="/business/history">
							{this.renderPage(BusinessHistory, true )}
						</Route>

						<Route exact path="/admin">
							{this.renderPage(AdminLocations, true, true )}
						</Route>

						<Route path="/admin/view-location/:location">
							{this.renderPage(AdminViewLocation, true, true )}
						</Route>

						<Route path="/admin/businesses">
							{this.renderPage(AdminBusinesses, true, true )}
						</Route>

						<Route path="/admin/view-business/:business">
							{this.renderPage(AdminViewBusiness, true, true )}
						</Route>

						<Route exact path="/404">
							{this.renderPage(Page404, false )}
						</Route>

                        <Route component={Page404} />

					</Switch>
				</Router>
				<ToastContainer />
			</React.Fragment>
		)

	}
}

export default App