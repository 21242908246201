import React from 'react'
import axios from 'axios'
import qs from 'qs'

import { withRouter } from 'react-router-dom'
import { loadStripe } from '@stripe/stripe-js'
import { CardElement, Elements, ElementsConsumer } from '@stripe/react-stripe-js'

import Sidebar from './sidebar.jsx'
import { core, toastError, stripe_api } from '../../../config.js'

declare var jQuery: any

const stripePromise = loadStripe(stripe_api);

class App extends React.Component {

	constructor( ) {

		super( )
		this.state = {

			name: '',
			phone: '',
			address: '',
			city: '',
			zipcode: '',
			contact: '',
			contactPosition: '',
			website: '',
			logo: '',

			loading: true,
			logoLoading: false,

			customer: null,
			plan: 'yearly',
			modal: false,
			modalLoading: false

		}

	}

	componentDidMount( ) {

		axios.post(core, qs.stringify({

            existsBusiness: true,
            secret: this.props.match.params.secret

        })).then((response) => {

            if( response.data[0] !== 'success' )
            	this.props.history.push('/404')
            else
            	this.setState({ loading: false }, ( ) => {

            		jQuery('body').on('change paste keyup', '.error', function( ) {

			            jQuery(this).removeClass('error')
			            jQuery(this).parent().removeClass('error')

			        })

            	})

        }).catch((error) => console.log(error))

	}

	modalLoading = ( state ) => {

		this.setState({ modalLoading: state })

	}

	logo = ( e ) => {

		let photo = e.target.files[0]

		if( photo.type !== 'image/jpeg' && photo.type !== 'image/jpg' && photo.type !== 'image/png' ) {

			toastError('Invalid image.')
			return false

		}

		if( photo.size > 5000000 ) {

			toastError('Maximum image size is 5MB.')
			return false

		}

		this.setState({ logoLoading: true }, ( ) => {

			const formData = new FormData( )
			formData.append('registerStep1BusinessLogo', true)
			formData.append('image', photo)
			formData.append('old', this.state.logo)
			formData.append('secret', this.props.match.params.secret)

			axios.post(core,

				formData,
				{
		            headers: {
		                'Content-Type': 'multipart/form-data'
		            }
		        }

		   	).then((response) => {

		   		jQuery('#input-logo').val('')

				if( response.data[0] === 'success' )
					this.setState({ logo: `${core}/${response.data[1]}`, logoLoading: false })
				else
					toastError(response.data[1])

			}).catch((error) => console.log(error))

		})

	}

	submit = ( ) => {

		let die = false

		if( !this.state.name.length ) {

			jQuery('#input-name').parent().addClass('error')
			die = true

		}

		if( !this.state.phone.length ) {

			jQuery('#input-phone').parent().addClass('error')
			die = true

		}

		if( !this.state.address.length ) {

			jQuery('#input-address').parent().addClass('error')
			die = true

		}

		if( !this.state.city.length ) {

			jQuery('#input-city').parent().addClass('error')
			die = true

		}

		if( !this.state.zipcode.length ) {

			jQuery('#input-zipcode').parent().addClass('error')
			die = true

		}

		if( die ) return false

		axios.post(core, qs.stringify({

            registerStep1Business: true,
            name: this.state.name,
            phone: this.state.phone,
            address: this.state.address,
            city: this.state.city,
            zipcode: this.state.zipcode,
            contact: this.state.contact,
			contactPosition: this.state.contactPosition,
			website: this.state.website,
			logo: this.state.logo,
            secret: this.props.match.params.secret

        })).then((response) => {

            if( response.data[0] === 'success' )
            	this.setState({ customer: response.data[1], modal: true })


        }).catch((error) => console.log(error))

	}

	render( ) {

		if( this.state.loading ) return null

		return (

			<div className="business">
				<Sidebar />

				<div className="page-content">
					<div className="page-title">Udfyld din profil</div>

					<div className="inputs-form">
						<div className="f-input-group">
							<div className="input-label">Firmanavn *</div>
							<input type="text" id="input-name" className="input-text" placeholder="Skriv firmanavn" onChange={( e ) => this.setState({ name: e.target.value }) } />
						</div>
						<div className="f-input-group">
							<div className="input-label">Telefon*</div>
							<input type="text" id="input-phone" className="input-text" placeholder="Skriv telefonnummer" onChange={( e ) => this.setState({ phone: e.target.value }) } />
						</div>
						<div className="f-input-group">
							<div className="input-label">Adresse*</div>
							<input type="text" id="input-address" className="input-text" placeholder="Indtast gadenavn" onChange={( e ) => this.setState({ address: e.target.value }) } />
						</div>
						<div className="f-input-group">
							<div className="input-label">By*</div>
							<input type="text" id="input-city" className="input-text" placeholder="Skriv by" onChange={( e ) => this.setState({ city: e.target.value }) } />
						</div>
						<div className="f-input-group">
							<div className="input-label">Postnummer*</div>
							<input type="number" id="input-zipcode" className="input-text" placeholder="Skriv postnummer" onChange={( e ) => this.setState({ zipcode: e.target.value }) } />
						</div>
						<div className="f-input-group">
							<div className="input-label">Kontaktperson</div>
							<input type="text" className="input-text" placeholder="Skriv navn" onChange={( e ) => this.setState({ contact: e.target.value }) } />
						</div>
						<div className="f-input-group">
							<div className="input-label">Job position</div>
							<input type="text" className="input-text" placeholder="Skriv her" onChange={( e ) => this.setState({ contactPosition: e.target.value }) } />
						</div>
						<div className="f-input-group">
							<div className="input-label">Hjemmeside</div>
							<input type="text" className="input-text" placeholder="Skriv her" onChange={( e ) => this.setState({ website: e.target.value }) } />
						</div>
						<div className="f-input-group">
							<div className="input-label">Firma logo</div>
							<div className="img-drop" onClick={( ) => jQuery('#input-logo').click()} style={{ background: this.state.logo.length ? `url(${this.state.logo}) no-repeat center center` : '#fff' }}>
								{this.state.logoLoading ? <div className="spinner-border text-secondary"></div> :

									<svg width="24px" height="24px" viewBox="0 0 24 24">
									    <g id="Companies" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
									        <g id="Business-Finish-Step-1" transform="translate(-677.000000, -1232.000000)" className="fill-color" fill="#A4A7B5" fillRule="nonzero">
									            <g id="Input-Copy-4" transform="translate(475.000000, 1083.000000)">
									                <g id="Input" transform="translate(0.000000, 33.000000)">
									                    <g id="Base">
									                        <path d="M214,116 C207.372583,116 202,121.372583 202,128 C202,134.627417 207.372583,140 214,140 C220.627417,140 226,134.627417 226,128 C226,121.372583 220.627417,116 214,116 Z M219,129 L215,129 L215,133 C215,133.552285 214.552285,134 214,134 C213.447715,134 213,133.552285 213,133 L213,129 L209,129 C208.447715,129 208,128.552285 208,128 C208,127.447715 208.447715,127 209,127 L213,127 L213,123 C213,122.447715 213.447715,122 214,122 C214.552285,122 215,122.447715 215,123 L215,127 L219,127 C219.552285,127 220,127.447715 220,128 C220,128.552285 219.552285,129 219,129 Z" id="Shape"></path>
									                    </g>
									                </g>
									            </g>
									        </g>
									    </g>
									</svg>
								}

							</div>
							<input type="file" accept="image/jpg, image/jpeg, image/png" id="input-logo" className="d-none" onChange={(e) => this.logo(e)} />
						</div>

						<button type="button" className="btn btn-primary finish-btn" onClick={( ) => this.submit()}>Fortsæt</button>
					</div>
				</div>

				{this.state.modal ? (

					<div className="stripe-modal animated fadeIn">

						{!this.state.modalLoading ? (

							<React.Fragment>

								<div className="s-title">Abonner på vores plan</div>

								<div className="card-pricing-options mb-5©">
		                            <p className="text-dark">Betal månedligt</p>
		                            <div className="custom-control custom-switch custom-switch-secondary">
		                                <input type="checkbox" className="custom-control-input" defaultChecked={true} id="customSwitch1" onChange={( e ) => this.setState({ plan: e.target.checked ? 'yearly' : 'monthly' })} />
		                                <label className="custom-control-label text-dark text-left noselect" htmlFor="customSwitch1">Eller betal Årligtt <br /><span className="text-secondary">Spar yderligere 15%</span></label>
		                            </div>
		                        </div>

								<Elements stripe={stripePromise}>
									<InjectedCheckoutForm customer={this.state.customer} secret={this.props.match.params.secret} modalLoading={this.modalLoading} plan={this.state.plan} />
								</Elements>

							</React.Fragment>

						) : (

							<div className="animated fadeIn">
								<div className="spinner-border text-secondary" />
							</div>

						)}

					</div>

				) : null}

			</div>

		)

	}

}

class CheckoutForm extends React.Component {

	constructor( ) {

		super( )
		this.state = {

			name: ''

		}

	}

	handleSubmit = async (event) => {

		event.preventDefault( )

		const {stripe, elements} = this.props

		const { name } = this.state

		if( !name.length ) {

			jQuery('.stripe-input-name').addClass('error')
			return false

		}

		const {error, paymentMethod} = await stripe.createPaymentMethod({

			type: 'card',
			card: elements.getElement(CardElement),
			billing_details: {
				name
			}

		})

		if (!error) {

			this.props.modalLoading(true)

			axios.post(core, qs.stringify({

	            registerStep1BusinessSubscribe: true,
	            paymentMethod: paymentMethod.id,
	            customer: this.props.customer,
	            plan: this.props.plan,
	            secret: this.props.secret

	        })).then((response) => {

	       		if( response.data[0] === 'success' ) {

	       			jQuery('.stripe-modal').removeClass('animated').fadeOut(( ) => {

		        		window.location.href = '/business/register/step2/' + this.props.secret

		        	})

	       		}

	       		else
	       			this.props.modalLoading(false)

	        }).catch((error) => console.log(error))

		}

	}

	render( ) {

		return (

			<form onSubmit={this.handleSubmit} className="stripe-form">

				<input type="text" placeholder="Type cardholder name here.." className="stripe-input-name" onChange={( e ) => this.setState({ name: e.target.value })} />
				<CardElement
					options={{
						style: {
							base: {
								color: '#0D182F',
								fontFamily: '"hk_grotesksemibold", sans-serif',
								fontSize: '16px',
							 	'::placeholder': {
										color: '#aab7c4'
									}
							},
							invalid: {
								color: '#danger',
								iconColor: '#danger'
							}
						}
					}}
				/>


				<button type="submit" className="btn btn-primary">
					Pay
				</button>
				<p className="s-info">You will be charged for {this.props.plan === 'yearly' ? '510.00 DKK per year' : '50.00 DKK per month'}.</p>
			</form>

		)

	}

}

const InjectedCheckoutForm = ( { customer, secret, modalLoading, plan } ) => (

	<ElementsConsumer>

		{({stripe, elements}) => <CheckoutForm stripe={stripe} elements={elements} customer={customer} secret={secret} modalLoading={modalLoading} plan={plan} />}

	</ElementsConsumer>

)

export default withRouter(App)
