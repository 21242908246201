import React from 'react'

import { withRouter } from 'react-router-dom'

import { admin_secret } from '../../../config.js'

class App extends React.Component {

	componentDidMount( ) {

		localStorage.setItem('authkey', this.props.match.params.secret)

		if( this.props.match.params.secret === admin_secret )
        	window.location.pathname = '/admin'
        else
        	window.location.pathname = '/business'

	}

	render( ) {

		return null

	}

}

export default withRouter(App)