import React from 'react'
import axios from 'axios'
import qs from 'qs'

import Dropzone from 'react-dropzone'
import PlacesAutocomplete, { geocodeByAddress } from 'react-places-autocomplete'
import { withRouter } from 'react-router-dom'

import { core, toastError } from '../../../config.js'
import Sidebar from '../sidebar.jsx'

declare var jQuery: any

class App extends React.Component {

	constructor( ) {

		super( )
		this.state = {

			name: '',
			location: {},
			category: 0,
			subcategory: 0,
			hour1: '',
			hour2: '',
			other: '',
			description: '',
			cover: '',
			photosString: '',

			categories: [ ],
			photos: [ ],
			locationString: '',
			locationCity: '',
			coverLoading: false,
			photosLoading: false,

			loading: true,

			secret: localStorage.getItem('authkey')

		}

	}

	componentDidMount( ) {

		jQuery('body').on('change paste keyup', '.error input', function( ) {

            jQuery(this).parent().removeClass('error')

        })

        axios.get(`${core}?getCategoriesAndSubcategories`).then((response) => {

			if( response.data[0] === 'success' )
				this.setState({ categories: response.data[1] }, ( ) => {

					axios.post(core, qs.stringify({

			            businessLocation: true,
			            location: this.props.match.params.location,
			            secret: this.state.secret

			        })).then((response) => {

			            if( response.data[0] === 'success' ) {

			            	const hours = response.data[1].l_hours.split(' - ')

			            	this.setState({

			            		name: response.data[1].l_name,
			            		locationString: response.data[1].l_address,
			            		locationCity: response.data[1].l_city,
			            		location: JSON.parse(response.data[1].l_location),
			            		category: Number(response.data[1].l_category),
			            		subcategory: Number(response.data[1].l_subcategory),
			            		hour1: hours[0],
			            		hour2: hours[1],
			            		other: response.data[1].l_other,
			            		description: response.data[1].l_description,
			            		cover: response.data[1].l_cover,
			            		photosString: response.data[1].l_photos,
			            		photos: response.data[1].l_photos.split(','),
			            		loading: false

			            	})
			        	}

			            else
			            	this.props.history.push('/404')

			        }).catch((error) => console.log(error))

				})

		}).catch((error) => console.log(error))

	}

	cover = ( acceptedFiles ) => {

		let photo = acceptedFiles[0]

		if( photo.type !== 'image/jpeg' && photo.type !== 'image/jpg' && photo.type !== 'image/png' ) {

			toastError('Invalid image.')
			return false

		}

		if( photo.size > 5000000 ) {

			toastError('Maximum image size is 5MB.')
			return false

		}

		this.setState({ coverLoading: true }, ( ) => {

			const formData = new FormData( )
			formData.append('registerStep2BusinessCover', true)
			formData.append('image', photo)
			formData.append('old', this.state.cover)
			formData.append('secret', this.state.secret)

			axios.post(core,

				formData,
				{
		            headers: {
		                'Content-Type': 'multipart/form-data'
		            }
		        }

		   	).then((response) => {

				if( response.data[0] === 'success' )
					this.setState({ cover: `${core}/${response.data[1]}`, coverLoading: false })
				else
					toastError(response.data[1])

			}).catch((error) => console.log(error))

		})

	}

	photos = ( acceptedFiles ) => {

		// eslint-disable-next-line
		acceptedFiles.map((node) => {

			if( this.state.photos.length < 6 && (node.type === 'image/jpeg' || node.type === 'image/jpg' || node.type === 'image/png' ) && node.size < 5000000 )
				this.setState({ photos: [...this.state.photos, node] })

		})

	}

	countNewPhotos = ( ) => {

		let total = 0

		// eslint-disable-next-line
		this.state.photos.map((node, i) => {

			if( typeof node !== 'string' )
				total++

		})

		return total

	}

	submit = ( ) => {

		let die = false

		if( !this.state.name.length ) {

			jQuery('#input-name').parent().addClass('error')
			die = true

		}

		if( !this.state.locationString.length ) {

			jQuery('#input-location').parent().addClass('error')
			die = true

		}

		if( !this.state.category ) {

			jQuery('#select-category').parents('.f-input-group').addClass('error')
			die = true

		}

		if( !this.state.subcategory ) {

			jQuery('#select-subcategory').parents('.f-input-group').addClass('error')
			die = true

		}

		if( !this.state.hour1.length ) {

			jQuery('#select-hour1').parent().parent().addClass('error')
			jQuery('#hours-label').addClass('text-danger')
			die = true

		}

		if( !this.state.hour2.length ) {

			jQuery('#select-hour2').parent().parent().addClass('error')
			jQuery('#hours-label').addClass('text-danger')
			die = true

		}

		if( !this.state.other.length ) {

			jQuery('#input-other').parent().addClass('error')
			die = true

		}

		if( !this.state.description.length ) {

			jQuery('#textarea-description').parent().addClass('error')
			die = true

		}

		if( die ) return false

		if( this.state.photos.length && this.countNewPhotos( ) ) {

			this.setState({ photosLoading: true }, ( ) => {

				const formData = new FormData( )
				formData.append('registerStep2BusinessPhotos', true)

				// eslint-disable-next-line
				this.state.photos.map((node, i) => {

					if( typeof node !== 'string' )
						formData.append(`image${i}`, node)

				})

				formData.append('secret', this.state.secret)

				axios.post(core,

					formData,
					{
			            headers: {
			                'Content-Type': 'multipart/form-data'
			            }
			        }

			   	).then((response) => {

					if( response.data[0] === 'success' )
						this.setState({ photosString: `${this.state.photosString},${response.data[1].join(',')}` }, ( ) => this.finish())

				}).catch((error) => console.log(error))

			})

		}

		else
			this.finish()

	}

	finish = ( ) => {

		axios.post(core, qs.stringify({

            businessEditLocation: true,
            name: this.state.name,
			location: JSON.stringify(this.state.location),
			address: this.state.locationString,
			city: this.state.locationCity,
			category: this.state.category,
			subcategory: this.state.subcategory,
			hours: `${this.state.hour1} - ${this.state.hour2}`,
			other: this.state.other,
			description: this.state.description,
			cover: this.state.cover,
			photos: this.state.photosString,
            secret: this.state.secret,
            id: this.props.match.params.location

        })).then((response) => {

            if( response.data[0] === 'success' )
            	this.setState({ photosLoading: false }, ( ) => this.props.history.push(`/business/view-location/${this.props.match.params.location}`))

        }).catch((error) => console.log(error))

	}


	handleSelect = address => {

		this.setState({ locationString: this.simplify(address) }, ( ) => {

			geocodeByAddress(address)
	    	.then(results => {

	    		var city = jQuery.grep(results[0].address_components, function(x) {
			         return jQuery.inArray('locality', x.types) !== -1
			    })[0].short_name

	    		this.setState({ location: {

	    			lat: results[0].geometry.location.lat(),
	    			lng: results[0].geometry.location.lng()

	    		}, locationCity: city })

	    	})
	      	.catch(error => console.error('Error', error))


		})

	}

	simplify = ( text ) => {

		let arr = text.split(',')
		arr = arr.slice(0, -1)
		text = arr.join(',')

		return text

	}

	render() {

		const renderFunc = ({ getInputProps, getSuggestionItemProps, suggestions }) => (

		  	<div className="f-input-group">
		  		<div className="input-label">Kort placering</div>
		    	<input type="text" className="input-text" id="input-location" placeholder="Enter location" {...getInputProps()} />
		    	{suggestions.length ? (
			    	<div className="autocomplete-dropdown-container">
				      	{suggestions.map(suggestion => (

				      		!suggestion.types.includes('country') ? (

				      			<div {...getSuggestionItemProps(suggestion)}>
			          				<span>{this.simplify(suggestion.description)}</span>
			        			</div>) : null

				      	))}
			    	</div>
		    	) : null}
		  	</div>

		)

		if( this.state.loading ) return null

		return (
			<div className="business">
				<Sidebar />

				<div className="page-content">

					<div className="page-breadcrumb" onClick={( ) => this.props.history.push(`/business/view-location/${this.props.match.params.location}`)}>
						<img src="/landing/assets/images/back.svg" alt="" />
						<span>Tilbage</span>
					</div>

					<div className="page-title">Rediger info</div>

					<div className="inputs-form">
						<div className="f-input-group">
							<div className="input-label">Navn</div>
							<input type="text" className="input-text" id="input-name" placeholder="Skriv navn" onChange={(e) => this.setState({ name: e.target.value })} value={this.state.name} />
						</div>

						<PlacesAutocomplete value={this.state.locationString} onChange={( text ) => this.setState({ locationString: text })} onSelect={this.handleSelect} searchOptions={{ componentRestrictions: {country: 'dk'} }}>
							{renderFunc}
						</PlacesAutocomplete>

						<div className="f-input-group">
							<div className="input-label">Kategori</div>
							<div className="position-relative">
								<select className="input-select" id="select-category" onChange={(e) => {

									this.setState({ category: e.target.value, subcategory: 0 },

										( ) => {

											jQuery('#select-subcategory').val('')
											jQuery('#select-category').parents('.f-input-group').removeClass('error')

										})

									}}>
									<option value="" disabled selected={!this.state.category} hidden>Vælg</option>

									{/* eslint-disable-next-line */}
									{this.state.categories.map((node, i) => <option key={`category-${i}`} value={node.c_id} selected={this.state.category == node.c_id}>{node.c_name}</option>)}

								</select>
							</div>
						</div>
						<div className="f-input-group">
							<div className="input-label">Underkategori</div>
							<div className="position-relative">
								<select className="input-select" id="select-subcategory" onChange={(e) => this.setState({ subcategory: e.target.value }, ( ) => jQuery('#select-subcategory').parents('.f-input-group').removeClass('error'))}>
									<option value="" disabled selected={!this.state.subcategory} hidden>Select</option>
									{this.state.category ?

										// eslint-disable-next-line
										this.state.categories[this.state.category - 1].subcategories.split(',').map((node, i) => <option key={`${node}-${i}`} value={node.split('#')[1]} selected={this.state.subcategory == node.split('#')[1]}>{node.split('#')[0]}</option>)

									: null}

								</select>
							</div>
						</div>
						<div className="f-input-group">
							<div className="input-label" id="hours-label">Åbningstider</div>
							<div className="row">

								<div className="col-6">
									<div className="f-input-group">
									<div className="position-relative">

										<select className="input-select" id="select-hour1" onChange={(e) => this.setState({ hour1: e.target.value }, ( ) => {

											jQuery('#select-hour1').parents('.f-input-group').removeClass('error')
											if( this.state.hour1.length && this.state.hour2.length ) jQuery('#hours-label').removeClass('text-danger')

										})}>
											<option value="" disabled hidden>Vælg</option>
											<option value="00:00" selected={this.state.hour1 === '00:00' ? true : false}>00:00</option>
											<option value="00:30" selected={this.state.hour1 === '00:30' ? true : false}>00:30</option>
											<option value="01:00" selected={this.state.hour1 === '01:00' ? true : false}>01:00</option>
											<option value="01:30" selected={this.state.hour1 === '01:30' ? true : false}>01:30</option>
											<option value="02:00" selected={this.state.hour1 === '02:00' ? true : false}>02:00</option>
											<option value="02:30" selected={this.state.hour1 === '02:30' ? true : false}>02:30</option>
											<option value="03:00" selected={this.state.hour1 === '03:00' ? true : false}>03:00</option>
											<option value="03:30" selected={this.state.hour1 === '03:30' ? true : false}>03:30</option>
											<option value="04:00" selected={this.state.hour1 === '04:00' ? true : false}>04:00</option>
											<option value="04:30" selected={this.state.hour1 === '04:30' ? true : false}>04:30</option>
											<option value="05:00" selected={this.state.hour1 === '05:00' ? true : false}>05:00</option>
											<option value="05:30" selected={this.state.hour1 === '05:30' ? true : false}>05:30</option>
											<option value="06:00" selected={this.state.hour1 === '06:00' ? true : false}>06:00</option>
											<option value="06:30" selected={this.state.hour1 === '06:30' ? true : false}>06:30</option>
											<option value="07:00" selected={this.state.hour1 === '07:00' ? true : false}>07:00</option>
											<option value="07:30" selected={this.state.hour1 === '07:30' ? true : false}>07:30</option>
											<option value="08:00" selected={this.state.hour1 === '08:00' ? true : false}>08:00</option>
											<option value="08:30" selected={this.state.hour1 === '08:30' ? true : false}>08:30</option>
											<option value="09:00" selected={this.state.hour1 === '09:00' ? true : false}>09:00</option>
											<option value="09:30" selected={this.state.hour1 === '09:30' ? true : false}>09:30</option>
											<option value="10:00" selected={this.state.hour1 === '10:00' ? true : false}>10:00</option>
											<option value="10:30" selected={this.state.hour1 === '10:30' ? true : false}>10:30</option>
											<option value="11:00" selected={this.state.hour1 === '11:00' ? true : false}>11:00</option>
											<option value="11:30" selected={this.state.hour1 === '11:30' ? true : false}>11:30</option>
											<option value="12:00" selected={this.state.hour1 === '12:00' ? true : false}>12:00</option>
											<option value="12:30" selected={this.state.hour1 === '12:30' ? true : false}>12:30</option>
											<option value="13:00" selected={this.state.hour1 === '13:00' ? true : false}>13:00</option>
											<option value="13:30" selected={this.state.hour1 === '13:30' ? true : false}>13:30</option>
											<option value="14:00" selected={this.state.hour1 === '14:00' ? true : false}>14:00</option>
											<option value="14:30" selected={this.state.hour1 === '14:30' ? true : false}>14:30</option>
											<option value="15:00" selected={this.state.hour1 === '15:00' ? true : false}>15:00</option>
											<option value="15:30" selected={this.state.hour1 === '15:30' ? true : false}>15:30</option>
											<option value="16:00" selected={this.state.hour1 === '16:00' ? true : false}>16:00</option>
											<option value="16:30" selected={this.state.hour1 === '16:30' ? true : false}>16:30</option>
											<option value="17:00" selected={this.state.hour1 === '17:00' ? true : false}>17:00</option>
											<option value="17:30" selected={this.state.hour1 === '17:30' ? true : false}>17:30</option>
											<option value="18:00" selected={this.state.hour1 === '18:00' ? true : false}>18:00</option>
											<option value="18:30" selected={this.state.hour1 === '18:30' ? true : false}>18:30</option>
											<option value="19:00" selected={this.state.hour1 === '19:00' ? true : false}>19:00</option>
											<option value="19:30" selected={this.state.hour1 === '19:30' ? true : false}>19:30</option>
											<option value="20:00" selected={this.state.hour1 === '20:00' ? true : false}>20:00</option>
											<option value="20:30" selected={this.state.hour1 === '20:30' ? true : false}>20:30</option>
											<option value="21:00" selected={this.state.hour1 === '21:00' ? true : false}>21:00</option>
											<option value="21:30" selected={this.state.hour1 === '21:30' ? true : false}>21:30</option>
											<option value="22:00" selected={this.state.hour1 === '22:00' ? true : false}>22:00</option>
											<option value="22:30" selected={this.state.hour1 === '22:30' ? true : false}>22:30</option>
											<option value="23:00" selected={this.state.hour1 === '23:00' ? true : false}>23:00</option>
											<option value="23:30" selected={this.state.hour1 === '23:30' ? true : false}>23:30</option>
										</select>
									</div>
									</div>
								</div>

								<div className="col-6">

									<div className="f-input-group">
									<div className="position-relative">
										<select className="input-select" id="select-hour2" onChange={(e) => this.setState({ hour2: e.target.value }, ( ) => {

											jQuery('#select-hour2').parents('.f-input-group').removeClass('error')
											if( this.state.hour1.length && this.state.hour2.length ) jQuery('#hours-label').removeClass('text-danger')

										})}>
											<option value="" disabled hidden>Vælg</option>
											<option value="00:00" selected={this.state.hour2 === '00:00' ? true : false}>00:00</option>
											<option value="00:30" selected={this.state.hour2 === '00:30' ? true : false}>00:30</option>
											<option value="01:00" selected={this.state.hour2 === '01:00' ? true : false}>01:00</option>
											<option value="01:30" selected={this.state.hour2 === '01:30' ? true : false}>01:30</option>
											<option value="02:00" selected={this.state.hour2 === '02:00' ? true : false}>02:00</option>
											<option value="02:30" selected={this.state.hour2 === '02:30' ? true : false}>02:30</option>
											<option value="03:00" selected={this.state.hour2 === '03:00' ? true : false}>03:00</option>
											<option value="03:30" selected={this.state.hour2 === '03:30' ? true : false}>03:30</option>
											<option value="04:00" selected={this.state.hour2 === '04:00' ? true : false}>04:00</option>
											<option value="04:30" selected={this.state.hour2 === '04:30' ? true : false}>04:30</option>
											<option value="05:00" selected={this.state.hour2 === '05:00' ? true : false}>05:00</option>
											<option value="05:30" selected={this.state.hour2 === '05:30' ? true : false}>05:30</option>
											<option value="06:00" selected={this.state.hour2 === '06:00' ? true : false}>06:00</option>
											<option value="06:30" selected={this.state.hour2 === '06:30' ? true : false}>06:30</option>
											<option value="07:00" selected={this.state.hour2 === '07:00' ? true : false}>07:00</option>
											<option value="07:30" selected={this.state.hour2 === '07:30' ? true : false}>07:30</option>
											<option value="08:00" selected={this.state.hour2 === '08:00' ? true : false}>08:00</option>
											<option value="08:30" selected={this.state.hour2 === '08:30' ? true : false}>08:30</option>
											<option value="09:00" selected={this.state.hour2 === '09:00' ? true : false}>09:00</option>
											<option value="09:30" selected={this.state.hour2 === '09:30' ? true : false}>09:30</option>
											<option value="10:00" selected={this.state.hour2 === '10:00' ? true : false}>10:00</option>
											<option value="10:30" selected={this.state.hour2 === '10:30' ? true : false}>10:30</option>
											<option value="11:00" selected={this.state.hour2 === '11:00' ? true : false}>11:00</option>
											<option value="11:30" selected={this.state.hour2 === '11:30' ? true : false}>11:30</option>
											<option value="12:00" selected={this.state.hour2 === '12:00' ? true : false}>12:00</option>
											<option value="12:30" selected={this.state.hour2 === '12:30' ? true : false}>12:30</option>
											<option value="13:00" selected={this.state.hour2 === '13:00' ? true : false}>13:00</option>
											<option value="13:30" selected={this.state.hour2 === '13:30' ? true : false}>13:30</option>
											<option value="14:00" selected={this.state.hour2 === '14:00' ? true : false}>14:00</option>
											<option value="14:30" selected={this.state.hour2 === '14:30' ? true : false}>14:30</option>
											<option value="15:00" selected={this.state.hour2 === '15:00' ? true : false}>15:00</option>
											<option value="15:30" selected={this.state.hour2 === '15:30' ? true : false}>15:30</option>
											<option value="16:00" selected={this.state.hour2 === '16:00' ? true : false}>16:00</option>
											<option value="16:30" selected={this.state.hour2 === '16:30' ? true : false}>16:30</option>
											<option value="17:00" selected={this.state.hour2 === '17:00' ? true : false}>17:00</option>
											<option value="17:30" selected={this.state.hour2 === '17:30' ? true : false}>17:30</option>
											<option value="18:00" selected={this.state.hour2 === '18:00' ? true : false}>18:00</option>
											<option value="18:30" selected={this.state.hour2 === '18:30' ? true : false}>18:30</option>
											<option value="19:00" selected={this.state.hour2 === '19:00' ? true : false}>19:00</option>
											<option value="19:30" selected={this.state.hour2 === '19:30' ? true : false}>19:30</option>
											<option value="20:00" selected={this.state.hour2 === '20:00' ? true : false}>20:00</option>
											<option value="20:30" selected={this.state.hour2 === '20:30' ? true : false}>20:30</option>
											<option value="21:00" selected={this.state.hour2 === '21:00' ? true : false}>21:00</option>
											<option value="21:30" selected={this.state.hour2 === '21:30' ? true : false}>21:30</option>
											<option value="22:00" selected={this.state.hour2 === '22:00' ? true : false}>22:00</option>
											<option value="22:30" selected={this.state.hour2 === '22:30' ? true : false}>22:30</option>
											<option value="23:00" selected={this.state.hour2 === '23:00' ? true : false}>23:00</option>
											<option value="23:30" selected={this.state.hour2 === '23:30' ? true : false}>23:30</option>
										</select>
									</div>
									</div>

								</div>

							</div>
						</div>
						<div className="f-input-group">
							<div className="input-label">Andet</div>
							<input type="text" className="input-text" id="input-other" placeholder="Web address" onChange={(e) => this.setState({ other: e.target.value })} value={this.state.other} />
						</div>
						<div className="f-input-group">
							<div className="input-label">Description</div>
							<textarea className="input-text" id="textarea-description" placeholder="Short description" rows="4" cols="50" onChange={(e) => this.setState({ description: e.target.value }, ( ) => jQuery('#textarea-description').parent().removeClass('error') )} value={this.state.description}></textarea>
						</div>

						<div className="f-input-group">
							<div className="input-label">Coverbillede</div>
								<Dropzone onDrop={( acceptedFiles ) => this.cover(acceptedFiles)} maxSize={5000000}>
						        {({getRootProps, getInputProps}) => (
						        	<div {...getRootProps({ className: 'dropzone' })}>
										<div className="drop-area" style={{ background: this.state.cover.length ? `url(${this.state.cover}) no-repeat center center` : '#fff' }}>
											<input {...getInputProps()} />
											{this.state.coverLoading ? <div className="spinner-border text-secondary"></div> : 'Drop file here or click to upload.' }
										</div>
									</div>
						        )}
						    </Dropzone>
					    </div>

					    <div className="f-input-group">
							<div className="input-label">Ekstra fotos (max 6)</div>
								<Dropzone onDrop={( acceptedFiles ) => this.photos(acceptedFiles)} maxSize={5000000} multiple={true}>
						        {({getRootProps, getInputProps}) => (
						        	<div {...getRootProps({ className: 'dropzone' })}>
										<div className={this.state.photos.length ? 'drop-area with-preview' : 'drop-area'}>
											<input {...getInputProps()} />
											{this.state.photos.length ? (
												this.state.photos.map((node, i) => <img key={`preview${i}`} src={typeof node !== 'string' ? URL.createObjectURL(node) : node} alt="" />)
											) : 'Træk dine filer hertil, eller klik for at uploade' }
										</div>
									</div>
						        )}
						    </Dropzone>
								<p>Max 5mb pr. fil</p>
					    </div>

						<button type="button" className={this.state.photosLoading ? 'btn btn-primary finish-btn disabled pe-none' : 'btn btn-primary finish-btn' } onClick={( ) => this.submit()}>{this.state.photosLoading ? <div className="spinner-border spinner-border-sm"></div> : 'Gem information'}</button>
					</div>
				</div>
			</div>
		)

	}

}

export default withRouter(App)
