import React from 'react'

export default function App() {

	return (
		<div className="d-flex align-items-center justify-content-center flex-column">
			<img src="/landing/assets/images/404.png" alt="" style={{ width: '25%' }} />
			<h2>Page not found</h2>
		</div>
	)
}