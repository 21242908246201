import React from 'react'
import axios from 'axios'
import qs from 'qs'
import moment from 'moment'

import { withRouter } from 'react-router-dom'
import { core } from '../../config.js'

import Sidebar from './sidebar.jsx'

class App extends React.Component {

	constructor( ) {

		super( )
		this.state = {

			data: [ ],
			loading: true,

			secret: localStorage.getItem('authkey')

		}

	}

	componentDidMount( ) {

		axios.post(core, qs.stringify({

            businessTransactions: true,
            secret: this.state.secret

        })).then((response) => {

            if( response.data[0] === 'success' )
            	this.setState({ data: response.data[1], loading: false })

        }).catch((error) => console.log(error))

	}

	render( ) {

		if( this.state.loading ) return null

		return (
			<div className="business business-campaigns">
				<Sidebar />

				<div className="page-content">
					<div className="page-title">Betalingshistorik</div>

					<table className="transactions-table">
					    <thead>
					        <tr>
					            <th>Betalingsnummer</th>
					            <th>Dato</th>
					            <th>Beløb</th>
					            <th>Status</th>
					        </tr>
					    </thead>
					    <tbody>
					    	{this.state.data.map((node, i) => (
						        <tr key={`transaction${this.state.data.length - i}`}>
						            <td><span>Transaktionsnummer #{this.state.data.length - i}</span></td>
						            <td><span>{moment(node.created * 1000).format('MMM DD, YYYY')}</span></td>
						            <td><span>{node.amount / 100} kr.</span></td>
						            <td>
						            	<span className={node.status === 'succeeded' ? 'success' : 'danger'}>{node.status === 'succeeded' ? 'Afsluttet' : 'Afvist'}</span>
						            </td>
						        </tr>
						    ))}
					    </tbody>
					</table>
				</div>
			</div>
		)

	}
}

export default withRouter(App)
