import React from 'react'
import axios from 'axios'
import qs from 'qs'

import { withRouter } from 'react-router-dom'

import { core } from '../../../config.js'

declare var jQuery: any

class App extends React.Component {

	constructor( ) {

		super( )
		this.state = {

			password: '',
			loading: true

		}

	}

	componentDidMount( ) {

		axios.post(core, qs.stringify({

	        resetPasswordBusinessValidate: true,
	        hash: this.props.match.params.hash

        })).then((response) => {

            if( response.data[0] !== 'success' )
            	this.setState({ loading: false }, ( ) => this.props.history.push('/404'))
            else
            	this.setState({ loading: false })
            
        }).catch((error) => console.log(error))

	}

	submit = ( e ) => {

		e.preventDefault( )

		if( !this.state.password.length ) {

			jQuery('#input-password').parent().addClass('error')
			return false

		}

		axios.post(core, qs.stringify({

	        resetPasswordBusinessChange: true,
	        hash: this.props.match.params.hash,
	        password: this.state.password

        })).then((response) => {
        	
            if( response.data[0] === 'success' ) {

            	jQuery('#form-changepassword').fadeTo('normal', 0).addClass('pe-none')
            	jQuery('#title').text('Password has been changed')
                jQuery('#subtitle').text('Now you can login with the new password.')

            }

        }).catch((error) => console.log(error))

	}

	render( ) {

		if( this.state.loading ) return null

		return (

			<div className="auth auth-reset-password">

				<header id="header" className="header">
				    <nav className="navbar navbar-expand-lg navbar-dark">
				        <div className="container">
				            <a className="navbar-brand" href="https://danguiden.dk/apps/desktop.html">
				                <img src="/landing/assets/images/logo.svg" alt="Logo" className="height-36" />
				            </a>
				            <button className="navbar-toggler border-none" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
				                <img src="/landing/assets/images/icons/ic-menu.svg" alt="" />
				            </button>

				            <div className="collapse navbar-collapse" id="navbarSupportedContent">
				                <ul className="navbar-nav ml-auto">
				                    <li className="close-button">
				                        <button className="navbar-toggler border-none" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="true" aria-label="Toggle navigation">
				                            <img src="/landing/assets/images/icons/ic-close.svg" alt="" />
				                        </button>
				                    </li>
				                    <li className="nav-item">
				                        <a className="nav-link" href="https://danguiden.dk/apps/desktop.html">Home</a>
				                    </li>
				                    <li className="nav-item">
				                        <a className="nav-link" href="https://danguiden.dk/company/pricing.html">For Companies</a>
				                    </li>
				                    <li className="nav-item">
				                        <a className="nav-link" href="https://danguiden.dk/company/contact.html">Contact</a>
				                    </li>
				                    <li className="nav-item">
				                        <a className="btn btn-outline-primary" href="https://danguiden.dk/auth/signup-left-cover.html">Kom igang</a>
				                    </li>
				                </ul>
				            </div>
				        </div>
				    </nav>
				</header>

				<section id="auth" className="auth pt-188 pb-72">
				    <div className="container">
				        <div className="row">
				            <div className="col-lg-4 offset-lg-4 col-md-6 offset-md-3 col-xs-12 col-sm-12">
				                <div className="content">
				                    <div className="heading text-center mb-32">
				                        <h2 id="title" className="text-hkgrotesk font-bold text-alt text-black mb-12">Change password</h2>
				                        <p id="subtitle" className="text-gray">Enter a new password for your account.</p>
				                    </div>
				                    <form id="form-changepassword" onSubmit={( e ) => this.submit(e)}>
				                        <div className="form-group">
				                            <label>Password</label>
				                            <input type="password" id="input-password" className="form-control" placeholder="Enter your new password" onChange={( e ) => this.setState({ password: e.target.value })} />
				                        </div>
				                        <div className="form-group mb-24">
				                            <button type="submit" className="btn btn-primary btn-block">Change password</button>
				                        </div>
				                        <div className="form-group">
				                            <a href="https://danguiden.dk/apps/desktop.html" className="font-semibold text-14 leading-24 text-primary"><i className="fas fa-arrow-left mr-8"></i> Back to homepage</a>
				                        </div>
				                    </form>
				                </div>
				            </div>
				        </div>
				    </div>
				</section>

			</div>

		)

	}

}

export default withRouter(App)