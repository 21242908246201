import React from 'react'
import axios from 'axios'
import qs from 'qs'

import Dropzone from 'react-dropzone'
import PlacesAutocomplete, { geocodeByAddress } from 'react-places-autocomplete'
import { withRouter } from 'react-router-dom'

import { core, toastError } from '../../../config.js'
import Sidebar from '../sidebar.jsx'

declare var jQuery: any

class App extends React.Component {

	constructor( ) {

		super( )
		this.state = {

			name: '',
			location: {},
			category: 0,
			subcategory: 0,
			hour1: '',
			hour2: '',
			other: '',
			description: '',
			cover: '',
			photosString: '',

			categories: [ ],
			photos: [ ],
			locationString: '',
			locationCity: '',
			coverLoading: false,
			photosLoading: false,
			dropzoneDisabled: false,

			secret: localStorage.getItem('authkey')

		}

	}

	componentDidMount( ) {

		const main = this

		jQuery('body').on('change paste keyup', '.error input', function( ) {

            jQuery(this).parent().removeClass('error')

        })

        jQuery('body').on('mouseover', '.dropzone-remove', function( ) {

            main.setState({ dropzoneDisabled: true })

        })

        jQuery('body').on('mouseout', '.dropzone-remove', function( ) {

            main.setState({ dropzoneDisabled: false })

        })

        axios.get(`${core}?getCategoriesAndSubcategories`).then((response) => {

			if( response.data[0] === 'success' )
				this.setState({ categories: response.data[1] })

		}).catch((error) => console.log(error))



	}

	cover = ( acceptedFiles ) => {

		let photo = acceptedFiles[0]

		if( photo.type !== 'image/jpeg' && photo.type !== 'image/jpg' && photo.type !== 'image/png' ) {

			toastError('Invalid image.')
			return false

		}

		if( photo.size > 5000000 ) {

			toastError('Maximum image size is 5MB.')
			return false

		}

		this.setState({ coverLoading: true }, ( ) => {

			const formData = new FormData( )
			formData.append('registerStep2BusinessCover', true)
			formData.append('image', photo)
			formData.append('old', this.state.cover)
			formData.append('secret', this.state.secret)

			axios.post(core,

				formData,
				{
		            headers: {
		                'Content-Type': 'multipart/form-data'
		            }
		        }

		   	).then((response) => {

				if( response.data[0] === 'success' )
					this.setState({ cover: `${core}/${response.data[1]}`, coverLoading: false })
				else
					toastError(response.data[1])

			}).catch((error) => console.log(error))

		})

	}

	photos = ( acceptedFiles ) => {

		// eslint-disable-next-line
		acceptedFiles.map((node) => {

			if( this.state.photos.length < 6 && (node.type === 'image/jpeg' || node.type === 'image/jpg' || node.type === 'image/png' ) && node.size < 5000000 )
				this.setState({ photos: [...this.state.photos, node] })

		})

	}

	submit = ( ) => {

		let die = false

		if( !this.state.name.length ) {

			jQuery('#input-name').parent().addClass('error')
			die = true

		}

		if( !this.state.locationString.length ) {

			jQuery('#input-location').parent().addClass('error')
			die = true

		}

		if( !this.state.category ) {

			jQuery('#select-category').parents('.f-input-group').addClass('error')
			die = true

		}

		if( !this.state.subcategory ) {

			jQuery('#select-subcategory').parents('.f-input-group').addClass('error')
			die = true

		}

		if( !this.state.hour1.length ) {

			jQuery('#select-hour1').parent().parent().addClass('error')
			jQuery('#hours-label').addClass('text-danger')
			die = true

		}

		if( !this.state.hour2.length ) {

			jQuery('#select-hour2').parent().parent().addClass('error')
			jQuery('#hours-label').addClass('text-danger')
			die = true

		}

		if( !this.state.description.length || this.state.description.length > 800 ) {

			jQuery('#textarea-description').parent().addClass('error')
			die = true

		}

		if( die ) return false

		if( this.state.photos.length ) {

			this.setState({ photosLoading: true }, ( ) => {

				const formData = new FormData( )
				formData.append('registerStep2BusinessPhotos', true)

				// eslint-disable-next-line
				this.state.photos.map((node, i) => {
					formData.append(`image${i}`, node)
				})

				formData.append('secret', this.state.secret)

				axios.post(core,

					formData,
					{
			            headers: {
			                'Content-Type': 'multipart/form-data'
			            }
			        }

			   	).then((response) => {

					if( response.data[0] === 'success' )
						this.setState({ photosString: response.data[1].join(',') }, ( ) => this.finish())

				}).catch((error) => console.log(error))

			})

		}

		else
			this.finish()

	}

	finish = ( ) => {

		axios.post(core, qs.stringify({

            registerStep2Business: true,
            name: this.state.name,
			location: JSON.stringify(this.state.location),
			address: this.state.locationString,
			city: this.state.locationCity,
			category: this.state.category,
			subcategory: this.state.subcategory,
			hours: `${this.state.hour1} - ${this.state.hour2}`,
			other: this.state.other,
			description: this.state.description,
			cover: this.state.cover,
			photos: this.state.photosString,
            secret: this.state.secret

        })).then((response) => {

            if( response.data[0] === 'success' )
            	this.setState({ photosLoading: false }, ( ) => this.props.history.push('/business'))

        }).catch((error) => console.log(error))

	}


	handleSelect = address => {

		this.setState({ locationString: this.simplify(address) }, ( ) => {

			geocodeByAddress(address)
	    	.then(results => {

	    		var city = jQuery.grep(results[0].address_components, function(x) {
			         return jQuery.inArray('locality', x.types) !== -1
			    })[0].short_name

	    		this.setState({ location: {

	    			lat: results[0].geometry.location.lat(),
	    			lng: results[0].geometry.location.lng()

	    		}, locationCity: city })

	    	})
	      	.catch(error => console.error('Error', error))


		})

	}

	simplify = ( text ) => {

		let arr = text.split(',')
		arr = arr.slice(0, -1)
		text = arr.join(',')

		return text

	}

	removeCoverPhoto = ( index ) => {

		let array = this.state.photos
		array.splice(index, 1)

		this.setState({ photos: array, dropzoneDisabled: false })	

	}

	render() {

		const renderFunc = ({ getInputProps, getSuggestionItemProps, suggestions }) => (

		  	<div className="f-input-group">
		  		<div className="input-label">Kort placering*</div>
		    	<input type="text" className="input-text" id="input-location" placeholder="Skriv placering" {...getInputProps()} />
		    	{suggestions.length ? (
			    	<div className="autocomplete-dropdown-container">
				      	{suggestions.map(suggestion => (

				      		!suggestion.types.includes('country') ? (

				      			<div {...getSuggestionItemProps(suggestion)}>
			          				<span>{this.simplify(suggestion.description)}</span>
			        			</div>) : null

				      	))}
			    	</div>
		    	) : null}
		  	</div>

		)

		if( this.state.loading ) return null

		return (
			<div className="business">
				<Sidebar />

				<div className="page-content">
					<div className="page-title">Tilføj placering</div>

					<div className="inputs-form">
						<div className="f-input-group">
							<div className="input-label">Navn*</div>
							<input type="text" className="input-text" id="input-name" placeholder="Skriv navn" onChange={(e) => this.setState({ name: e.target.value })} />
						</div>

						<PlacesAutocomplete value={this.state.locationString} onChange={( text ) => this.setState({ locationString: text })} onSelect={this.handleSelect} searchOptions={{ componentRestrictions: {country: 'dk'} }}>
							{renderFunc}
						</PlacesAutocomplete>

						<div className="f-input-group">
							<div className="input-label">Kategori*</div>
							<div className="position-relative">
								
								<select className="input-select" id="select-category" onChange={(e) => {

									this.setState({ category: e.target.value, subcategory: 0 },

										( ) => {

											jQuery('#select-subcategory').val('')
											jQuery('#select-category').parents('.f-input-group').removeClass('error')

										})

									}}>
									<option value="" disabled selected hidden>Select</option>

									{this.state.categories.map((node, i) => <option key={`category-${i}`} value={node.c_id}>{node.c_name}</option>)}

								</select>
							</div>
						</div>
						<div className="f-input-group">
							<div className="input-label">Underkategori*</div>
							<div className="position-relative">
								<select className="input-select" id="select-subcategory" onChange={(e) => this.setState({ subcategory: e.target.value }, ( ) => jQuery('#select-subcategory').parents('.f-input-group').removeClass('error'))}>
									<option value="" disabled selected hidden>Vælg</option>
									{this.state.category ?

										this.state.categories[this.state.category - 1].subcategories.split(',').map((node, i) => <option key={`${node}-${i}`} value={node.split('#')[1]}>{node.split('#')[0]}</option>)

									: null}

								</select>
							</div>
						</div>
						<div className="f-input-group">
							<div className="input-label" id="hours-label">Åbningstider*</div>
							<div className="row">

								<div className="col-6">
									<div className="f-input-group">
									<div className="position-relative">

										<select className="input-select" id="select-hour1" onChange={(e) => this.setState({ hour1: e.target.value }, ( ) => {

											jQuery('#select-hour1').parents('.f-input-group').removeClass('error')
											if( this.state.hour1.length && this.state.hour2.length ) jQuery('#hours-label').removeClass('text-danger')

										})}>
											<option value="" disabled selected hidden>Vælg</option>
											<option value="00:00">00:00</option>
											<option value="00:30">00:30</option>
											<option value="01:00">01:00</option>
											<option value="01:30">01:30</option>
											<option value="02:00">02:00</option>
											<option value="02:30">02:30</option>
											<option value="03:00">03:00</option>
											<option value="03:30">03:30</option>
											<option value="04:00">04:00</option>
											<option value="04:30">04:30</option>
											<option value="05:00">05:00</option>
											<option value="05:30">05:30</option>
											<option value="06:00">06:00</option>
											<option value="06:30">06:30</option>
											<option value="07:00">07:00</option>
											<option value="07:30">07:30</option>
											<option value="08:00">08:00</option>
											<option value="08:30">08:30</option>
											<option value="09:00">09:00</option>
											<option value="09:30">09:30</option>
											<option value="10:00">10:00</option>
											<option value="10:30">10:30</option>
											<option value="11:00">11:00</option>
											<option value="11:30">11:30</option>
											<option value="12:00">12:00</option>
											<option value="12:30">12:30</option>
											<option value="13:00">13:00</option>
											<option value="13:30">13:30</option>
											<option value="14:00">14:00</option>
											<option value="14:30">14:30</option>
											<option value="15:00">15:00</option>
											<option value="15:30">15:30</option>
											<option value="16:00">16:00</option>
											<option value="16:30">16:30</option>
											<option value="17:00">17:00</option>
											<option value="17:30">17:30</option>
											<option value="18:00">18:00</option>
											<option value="18:30">18:30</option>
											<option value="19:00">19:00</option>
											<option value="19:30">19:30</option>
											<option value="20:00">20:00</option>
											<option value="20:30">20:30</option>
											<option value="21:00">21:00</option>
											<option value="21:30">21:30</option>
											<option value="22:00">22:00</option>
											<option value="22:30">22:30</option>
											<option value="23:00">23:00</option>
											<option value="23:30">23:30</option>
										</select>
									</div>
									</div>
								</div>

								<div className="col-6">

									<div className="f-input-group">
									<div className="position-relative">
										<select className="input-select" id="select-hour2" onChange={(e) => this.setState({ hour2: e.target.value }, ( ) => {

											jQuery('#select-hour2').parents('.f-input-group').removeClass('error')
											if( this.state.hour1.length && this.state.hour2.length ) jQuery('#hours-label').removeClass('text-danger')

										})}>
											<option value="" disabled selected hidden>Vælg</option>
											<option value="00:00">00:00</option>
											<option value="00:30">00:30</option>
											<option value="01:00">01:00</option>
											<option value="01:30">01:30</option>
											<option value="02:00">02:00</option>
											<option value="02:30">02:30</option>
											<option value="03:00">03:00</option>
											<option value="03:30">03:30</option>
											<option value="04:00">04:00</option>
											<option value="04:30">04:30</option>
											<option value="05:00">05:00</option>
											<option value="05:30">05:30</option>
											<option value="06:00">06:00</option>
											<option value="06:30">06:30</option>
											<option value="07:00">07:00</option>
											<option value="07:30">07:30</option>
											<option value="08:00">08:00</option>
											<option value="08:30">08:30</option>
											<option value="09:00">09:00</option>
											<option value="09:30">09:30</option>
											<option value="10:00">10:00</option>
											<option value="10:30">10:30</option>
											<option value="11:00">11:00</option>
											<option value="11:30">11:30</option>
											<option value="12:00">12:00</option>
											<option value="12:30">12:30</option>
											<option value="13:00">13:00</option>
											<option value="13:30">13:30</option>
											<option value="14:00">14:00</option>
											<option value="14:30">14:30</option>
											<option value="15:00">15:00</option>
											<option value="15:30">15:30</option>
											<option value="16:00">16:00</option>
											<option value="16:30">16:30</option>
											<option value="17:00">17:00</option>
											<option value="17:30">17:30</option>
											<option value="18:00">18:00</option>
											<option value="18:30">18:30</option>
											<option value="19:00">19:00</option>
											<option value="19:30">19:30</option>
											<option value="20:00">20:00</option>
											<option value="20:30">20:30</option>
											<option value="21:00">21:00</option>
											<option value="21:30">21:30</option>
											<option value="22:00">22:00</option>
											<option value="22:30">22:30</option>
											<option value="23:00">23:00</option>
											<option value="23:30">23:30</option>
										</select>
									</div>
									</div>

								</div>

							</div>
						</div>
						<div className="f-input-group">
							<div className="input-label">Andet</div>
							<input type="text" className="input-text" id="input-other" placeholder="Hjemmeside" onChange={(e) => this.setState({ other: e.target.value })} />
						</div>
						<div className="f-input-group">
							<div className="input-label">Beskrivelse</div>
							<textarea className="input-text" id="textarea-description" placeholder="Kort beskrivelse" rows="4" cols="50" onChange={(e) => this.setState({ description: e.target.value }, ( ) => jQuery('#textarea-description').parent().removeClass('error') )}></textarea>
							<div className="input-info">Op til 800 tegn.</div>
						</div>

						<div className="f-input-group">
							<div className="input-label">Coverbillede</div>
								<Dropzone onDrop={( acceptedFiles ) => this.cover(acceptedFiles)} maxSize={5000000}>
						        {({getRootProps, getInputProps}) => (
						        	<div {...getRootProps({ className: 'dropzone' })}>
										<div className="drop-area" style={{ background: this.state.cover.length ? `url(${this.state.cover}) no-repeat center center` : '#fff' }}>
											<input {...getInputProps()} />
											{this.state.coverLoading ? <div className="spinner-border text-secondary"></div> : 'Træk dine filer hertil, eller klik for at uploade.' }
										</div>
									</div>
						        )}
						    </Dropzone>
					    </div>

					    <div className="f-input-group">
							<div className="input-label">Ekstra fotos (max 6)</div>
								<Dropzone onDrop={( acceptedFiles ) => this.photos(acceptedFiles)} maxSize={5000000} multiple={true} disabled={this.state.dropzoneDisabled}>
						        {({getRootProps, getInputProps}) => (
						        	<div {...getRootProps({ className: 'dropzone' })}>
										<div className={this.state.photos.length ? 'drop-area with-preview' : 'drop-area'}>
											<input {...getInputProps()} />
											{this.state.photos.length ? (
												this.state.photos.map((node, i) => (
													<div key={`preview${i}`} className="dropzone-preview">
														<i className="fas fa-times-circle dropzone-remove" onClick={( ) => this.removeCoverPhoto(i)}></i>
														<img src={URL.createObjectURL(node)} alt="" />
													</div>
												))
											) : 'Træk dine filer hertil, eller klik for at uploade.' }
										</div>
									</div>
						        )}
						    </Dropzone>
								<p>Max 5mb pr. fil</p>
					    </div>

						<button type="button" className={this.state.photosLoading ? 'btn btn-primary finish-btn disabled pe-none' : 'btn btn-primary finish-btn' } onClick={( ) => this.submit()}>{this.state.photosLoading ? <div className="spinner-border spinner-border-sm"></div> : 'Tilføj placering'}</button>
					</div>
				</div>
			</div>
		)

	}

}

export default withRouter(App)
