import React from 'react'
import axios from 'axios'
import qs from 'qs'

import { withRouter } from 'react-router-dom'
import { core } from '../../../config.js'

import Sidebar from '../sidebar.jsx'

class App extends React.Component {

	constructor( ) {

		super( )
		this.state = {

			data: [ ],
			loading: true,

			ids: [ ],

			secret: localStorage.getItem('authkey')

		}

	}

	componentDidMount( ) {

		axios.post(core, qs.stringify({

            businessLocations: true,
            secret: this.state.secret

        })).then((response) => {

            if( response.data[0] === 'success' )
            	this.setState({ data: response.data[1], loading: false })

        }).catch((error) => console.log(error))

	}

	checkbox = ( state, id ) => {

		let array = this.state.ids

		if( state ) {

			if( !array.includes(id) )
				array.push(id)

		}

		else {

			if( array.includes(id) ) {

				let index = array.indexOf(id)
				if (index !== -1) array.splice(index, 1)

			}

		}

		this.setState({ ids: array })

	}

	render( ) {

		if( this.state.loading ) return null

		return (
			<div className="business">
				<Sidebar />

				<div className="page-content">
					<div className="d-flex justify-content-between align-items-center">
						<div className="page-title">Vælg en placering for at fortsætte</div>
						<div className="add-location" onClick={( ) => this.props.history.push(`/business/add-campaign/${this.state.ids.join(',')}`)}>
							<span>Vælg og fortsæt</span>
							<img src="/landing/assets/images/ic_Plus.svg" alt="" />
						</div>
					</div>

					<table className="locations-table">
					    <thead>
					        <tr>
					            <th style={{ width: 64 }}></th>
					            <th>Placering</th>
					            <th>Kategori</th>
					            <th>Underkategori</th>
					            <th>By</th>
					        </tr>
					    </thead>
					    <tbody>
					    	{this.state.data.map((node, i) => (
						        <tr key={`location${i}`}>
						            <td style={{ width: 64 }}>
						            	<input className="styled-checkbox" id={`location-checkbox-${i}`} type="checkbox" onChange={( e ) => this.checkbox(e.target.checked, node.l_id)} />
										<label htmlFor={`location-checkbox-${i}`} className="fix mb-0"></label>
						            </td>
						            <td>
						            	<span>{node.l_name}</span>
						            	<span>{node.l_address}</span>
						            </td>
						            <td>
						            	<span>{node.c_name}</span>
						            </td>
						            <td>
						            	<span>{node.sc_name}</span>
						            </td>
						            <td>
						            	<span>{node.l_city}</span>
						            </td>
						        </tr>
						    ))}
					    </tbody>
					</table>

				</div>
			</div>
		)

	}
}

export default withRouter(App)
