import React from 'react'
import axios from 'axios'
import qs from 'qs'
import moment from 'moment'

import { withRouter } from 'react-router-dom'
import { core } from '../../../config.js'

import Sidebar from '../sidebar.jsx'

class App extends React.Component {

	constructor( ) {

		super( )
		this.state = {

			data: [ ],
			loading: true,

			secret: localStorage.getItem('authkey')

		}

	}

	componentDidMount( ) {

		axios.post(core, qs.stringify({

            businessCampaigns: true,
            secret: this.state.secret

        })).then((response) => {

            if( response.data[0] === 'success' )
            	this.setState({ data: response.data[1], loading: false })

        }).catch((error) => console.log(error))

	}

	renderTime = ( time ) => {

		let times = time.split(' - ')

		return (

			<React.Fragment>

				<span>{times[0]}</span>
				<span>{times[1]}</span>

			</React.Fragment>

		)

	}

	renderDays = ( days ) => {

		let a_days = days.split(',')

		return (

			<React.Fragment>

				<span>{moment(a_days[0], 'DD/MM/YYYY').format('MMM DD, YYYY')}</span>
				<span>{moment(a_days[a_days.length - 1], 'DD/MM/YYYY').format('MMM DD, YYYY')}</span>

			</React.Fragment>

		)

	}

	render( ) {

		if( this.state.loading ) return null

		return (
			<div className="business business-campaigns">
				<Sidebar />

				<div className="page-content">
					<div className="d-flex justify-content-between align-items-center">
						<div className="page-title">Annoncering</div>
						<div className="add-location" onClick={( ) => this.props.history.push('/business/add-campaign')}>
							<span>Opret ny</span>
							<img src="/landing/assets/images/ic_Plus.svg" alt="" />
						</div>
					</div>

					{this.state.data.length ? (

						<table className="campaigns-table">
						    <thead>
						        <tr>
						            <th>ID</th>
						            <th>Titel + beskrivelse</th>
						            <th>Placeringer</th>
						            <th>Tids interval</th>
						            <th>Start & slutdato</th>
						            <th></th>
						        </tr>
						    </thead>
						    <tbody>
						    	{this.state.data.map((node, i) => (
							        <tr key={`campaign${i}`}>
							            <td>{i + 1}</td>
							            <td>
							            	<span>{node.c_title}</span>
							            	<span>{node.c_description}</span>
							            </td>
							            <td>
							            	<span>{node.c_locations.split(',').length}</span>
							            </td>
							            <td>
							            	{this.renderTime(node.c_time)}
							            </td>
							            <td>
							            	{this.renderDays(node.c_days)}
							            </td>

							            <td>
							            	<span className="btn btn-primary" onClick={( ) => this.props.history.push(`/business/view-campaign/${node.c_id}`)}>Se detaljer</span>
							            </td>
							        </tr>
							    ))}
						    </tbody>
						</table>

					) : (

						<div className="no-ads">
							<img src="/landing/assets/images/noads.svg" alt="" />
							<span>Du har ikke nogen løbende kampagner<br />Tid til at oprette din første!</span>
						</div>

					)}

				</div>
			</div>
		)

	}
}

export default withRouter(App)
