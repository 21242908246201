import React from 'react'
import axios from 'axios'
import qs from 'qs'
import moment from 'moment'

import { withRouter } from 'react-router-dom'
import { core } from '../../../config.js'

import Sidebar from '../sidebar.jsx'

class App extends React.Component {

	constructor( ) {

		super( )
		this.state = {

			data: [ ],
			loading: true,

			secret: localStorage.getItem('authkey')

		}

	}

	componentDidMount( ) {

		axios.post(core, qs.stringify({

            businessLocations: true,
            secret: this.state.secret

        })).then((response) => {

        	console.log(response)

            if( response.data[0] === 'success' )
            	this.setState({ data: response.data[1], loading: false })

        }).catch((error) => console.log(error))

	}

	render( ) {

		if( this.state.loading ) return null

		return (
			<div className="business">
				<Sidebar />

				<div className="page-content">
					<div className="d-flex justify-content-between align-items-center">
						<div className="page-title">Dine placeringer</div>
						<div className="add-location" onClick={( ) => this.props.history.push('/business/add-location')}>
							<span>Tilføj placering</span>
							<img src="/landing/assets/images/ic_Plus.svg" alt="" />
						</div>
					</div>

					<table className="locations-table">
					    <thead>
					        <tr>
					            <th>ID</th>
					            <th>Placering</th>
					            <th>Kategori</th>
					            <th>Underkategori</th>
					            <th>By</th>
					            <th>Tilføjet den</th>
					            <th></th>
					        </tr>
					    </thead>
					    <tbody>
					    	{this.state.data.map((node, i) => (
						        <tr key={`location${i}`}>
						            <td>{i + 1}</td>
						            <td>
						            	<span>{node.l_name}</span>
						            	<span>{node.l_address}</span>
						            </td>
						            <td>
						            	<span>{node.c_name}</span>
						            </td>
						            <td>
						            	<span>{node.sc_name}</span>
						            </td>
						            <td>
						            	<span>{node.l_city}</span>
						            </td>
						            <td>
						            	<span>{moment(node.l_date).format('MMM DD, YYYY')}</span>
						            </td>
						            <td>
						            	<span className="btn btn-primary" onClick={( ) => this.props.history.push(`/business/view-location/${node.l_id}`)}>Se detaljer</span>
						            </td>
						        </tr>
						    ))}
					    </tbody>
					</table>

					<div className="add-location-footer" onClick={( ) => this.props.history.push('/business/add-location')}>
						<i className="fas fa-plus"></i>
					</div>
				</div>
			</div>
		)

	}
}

export default withRouter(App)
