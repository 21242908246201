import React from 'react'
import axios from 'axios'
import qs from 'qs'

import { withRouter } from 'react-router-dom'

import Sidebar from '../sidebar.jsx'
import { core, toastError } from '../../../config.js'

declare var jQuery: any

class App extends React.Component {

	constructor( ) {

		super( )
		this.state = {

			email: '',
			vat: '',
			name: '',
			phone: '',
			address: '',
			city: '',
			zipcode: '',
			contact: '',
			contactPosition: '',
			website: '',
			logo: '',

			loading: true,
			logoLoading: false,

			secret: localStorage.getItem('authkey')

		}

	}

	componentDidMount( ) {

		axios.post(core, qs.stringify({

            businessProfile: true,
            secret: this.state.secret

        })).then((response) => {

            if( response.data[0] === 'success' )
            	this.setState({

            		email: response.data[1].b_email,
            		vat: response.data[1].b_vat.replace('DK', ''),
            		name: response.data[1].b_name,
            		phone: response.data[1].b_phone,
            		address: response.data[1].b_address,
            		city: response.data[1].b_city,
            		zipcode: response.data[1].b_zipcode,
            		contact: response.data[1].b_contact,
            		contactPosition: response.data[1].b_contact_position,
            		website: response.data[1].b_website,
            		logo: response.data[1].b_logo,
            		loading: false

            	}, ( ) => {

            		jQuery('body').on('change paste keyup', '.error input', function( ) {

			            jQuery(this).parent().removeClass('error')
			            jQuery(this).parents('.f-input-group').removeClass('error')

			        })

            	})

        }).catch((error) => console.log(error))

	}

	logo = ( e ) => {

		let photo = e.target.files[0]

		if( photo.type !== 'image/jpeg' && photo.type !== 'image/jpg' && photo.type !== 'image/png' ) {

			toastError('Invalid image.')
			return false

		}

		if( photo.size > 5000000 ) {

			toastError('Maximum image size is 5MB.')
			return false

		}

		this.setState({ logoLoading: true }, ( ) => {

			const formData = new FormData( )
			formData.append('registerStep1BusinessLogo', true)
			formData.append('image', photo)
			formData.append('old', this.state.logo)
			formData.append('secret', this.props.match.params.secret)

			axios.post(core,

				formData,
				{
		            headers: {
		                'Content-Type': 'multipart/form-data'
		            }
		        }

		   	).then((response) => {

		   		jQuery('#input-logo').val('')

				if( response.data[0] === 'success' )
					this.setState({ logo: `${core}/${response.data[1]}`, logoLoading: false })
				else
					toastError(response.data[1])

			}).catch((error) => console.log(error))

		})

	}

	submit = ( ) => {

		let die = false

		// eslint-disable-next-line
		let re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

		if( !this.state.email.length ) {

			jQuery('#input-email').parent().addClass('error')
			die = true

		}

		if ( !this.state.email.match(re) ) {

            jQuery('#input-email').parent().addClass('error')
            die = true

        }

        if( this.state.vat.length !== 8 ) {

			jQuery('#input-vat').parents('.f-input-group').addClass('error')
			die = true

		}

		if( !this.state.name.length ) {

			jQuery('#input-name').parent().addClass('error')
			die = true

		}

		if( !this.state.phone.length ) {

			jQuery('#input-phone').parent().addClass('error')
			die = true

		}

		if( !this.state.address.length ) {

			jQuery('#input-address').parent().addClass('error')
			die = true

		}

		if( !this.state.city.length ) {

			jQuery('#input-city').parent().addClass('error')
			die = true

		}

		if( !this.state.zipcode.length ) {

			jQuery('#input-zipcode').parent().addClass('error')
			die = true

		}

		if( die ) return false

		axios.post(core, qs.stringify({

            businessProfileEdit: true,
            email: this.state.email,
            vat: 'DK' + this.state.vat,
            name: this.state.name,
            phone: this.state.phone,
            address: this.state.address,
            city: this.state.city,
            zipcode: this.state.zipcode,
            contact: this.state.contact,
			contactPosition: this.state.contactPosition,
			website: this.state.website,
			logo: this.state.logo,
            secret: this.state.secret

        })).then((response) => {

            if( response.data[0] === 'success' )
            	this.props.history.push('/business/profile')

        }).catch((error) => console.log(error))

	}

	render( ) {

		if( this.state.loading ) return null

		return (
			<div className="business">
				<Sidebar />

				<div className="page-content">
					<div className="page-title">Rediger din profil</div>

					<div className="inputs-form">
						<div className="f-input-group">
							<div className="input-label">Email adresse</div>
							<input type="email" id="input-email" className="input-text" placeholder="Indtast email-adresse" onChange={( e ) => this.setState({ email: e.target.value }) } value={this.state.email} />
						</div>
						<div className="f-input-group">
							<div className="input-label">CVR nummer*</div>
							<div className="position-relative">
								<span className="text-label">DK</span>
								<input type="number" id="input-vat" className="input-text with-text-label" placeholder="Indtast CVR nummer" onChange={( e ) => this.setState({ vat: e.target.value.toString().substring(0, 8) }) } value={this.state.vat} />
							</div>
						</div>
						<div className="f-input-group">
							<div className="input-label">Firmanavn</div>
							<input type="text" id="input-name" className="input-text" placeholder="Indtast firmanavn" onChange={( e ) => this.setState({ name: e.target.value }) } value={this.state.name} />
						</div>
						<div className="f-input-group">
							<div className="input-label">Telefon</div>
							<input type="text" id="input-phone" className="input-text" placeholder="Indtast nummer" onChange={( e ) => this.setState({ phone: e.target.value }) } value={this.state.phone} />
						</div>
						<div className="f-input-group">
							<div className="input-label">Adresse</div>
							<input type="text" id="input-address" className="input-text" placeholder="Gadenavn + nummer" onChange={( e ) => this.setState({ address: e.target.value }) } value={this.state.address} />
						</div>
						<div className="f-input-group">
							<div className="input-label">By</div>
							<input type="text" id="input-city" className="input-text" placeholder="Indtast by" onChange={( e ) => this.setState({ city: e.target.value }) } value={this.state.city} />
						</div>
						<div className="f-input-group">
							<div className="input-label">Postnummer</div>
							<input type="number" id="input-zipcode" className="input-text" placeholder="Indtast nummer" onChange={( e ) => this.setState({ zipcode: e.target.value }) } value={this.state.zipcode} />
						</div>
						<div className="f-input-group">
							<div className="input-label">Kontaktperson</div>
							<input type="text" className="input-text" placeholder="Fulde navn" onChange={( e ) => this.setState({ contact: e.target.value }) } value={this.state.contact} />
						</div>
						<div className="f-input-group">
							<div className="input-label">Job position</div>
							<input type="text" className="input-text" placeholder="Skriv her" onChange={( e ) => this.setState({ contactPosition: e.target.value }) }  value={this.state.contactPosition}/>
						</div>
						<div className="f-input-group">
							<div className="input-label">Hjemmeside</div>
							<input type="text" className="input-text" placeholder="Skriv her" onChange={( e ) => this.setState({ website: e.target.value }) } value={this.state.website} />
						</div>
						<div className="f-input-group">
							<div className="input-label">Firma Logo</div>
							<div className="img-drop" onClick={( ) => jQuery('#input-logo').click()} style={{ background: this.state.logo.length ? `url(${this.state.logo}) no-repeat center center` : '#fff' }}>
								{this.state.logoLoading ? <div className="spinner-border text-secondary"></div> :

									<svg width="24px" height="24px" viewBox="0 0 24 24">
									    <g id="Companies" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
									        <g id="Business-Finish-Step-1" transform="translate(-677.000000, -1232.000000)" className="fill-color" fill="#A4A7B5" fillRule="nonzero">
									            <g id="Input-Copy-4" transform="translate(475.000000, 1083.000000)">
									                <g id="Input" transform="translate(0.000000, 33.000000)">
									                    <g id="Base">
									                        <path d="M214,116 C207.372583,116 202,121.372583 202,128 C202,134.627417 207.372583,140 214,140 C220.627417,140 226,134.627417 226,128 C226,121.372583 220.627417,116 214,116 Z M219,129 L215,129 L215,133 C215,133.552285 214.552285,134 214,134 C213.447715,134 213,133.552285 213,133 L213,129 L209,129 C208.447715,129 208,128.552285 208,128 C208,127.447715 208.447715,127 209,127 L213,127 L213,123 C213,122.447715 213.447715,122 214,122 C214.552285,122 215,122.447715 215,123 L215,127 L219,127 C219.552285,127 220,127.447715 220,128 C220,128.552285 219.552285,129 219,129 Z" id="Shape"></path>
									                    </g>
									                </g>
									            </g>
									        </g>
									    </g>
									</svg>
								}

							</div>
							<input type="file" accept="image/jpg, image/jpeg, image/png" id="input-logo" className="d-none" onChange={(e) => this.logo(e)} />
						</div>

						<button type="button" className="btn btn-primary finish-btn" onClick={( ) => this.submit()}>Gem information</button>
					</div>
				</div>
			</div>
		)

	}

}

export default withRouter(App)
