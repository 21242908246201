import React from 'react'

import { GoogleApiWrapper, Map, Marker } from 'google-maps-react'
import { google_api } from '../config.js'

declare var jQuery: any

class App extends React.Component {

	render( ) {

		return (
			<Map
				google={this.props.google}
				zoom={18}
				initialCenter={{

		            lat: this.props.location.lat,
		            lng: this.props.location.lng

		        }}

		        className="viewlocation-map"
		        onReady={( ) => jQuery('.viewlocation-map').css('position', 'relative').parent().height( 270 ).children().children().css('border-radius', 10)}
			>
				<Marker />
			</Map>
		)

	}

}

export default GoogleApiWrapper({
	apiKey: (google_api)
})(App)