import { toast } from 'react-toastify'
import SweetAlert from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

declare var jQuery: any

export const core = 'https://api.danguiden.dk'
export const google_api = 'AIzaSyDKknVzGweyMEbvslWoKSOclZTZyVq3eOU'
export const stripe_api = 'pk_test_u2qJa5Z5lnmXQbObCdVcLtsP00hNTZZzlG'
export const campaign_price = 10
export const subscription_monthly = 'price_HJpmYs9CzzOQmj'
export const subscription_yearly = 'price_HJpmyBf6JyLK8w'
export const admin_secret = '6b71107737492b94e6f3971404265f999fdf296bcb6fdf9b9dd751e845678645'

export const toastSuccess = ( text ) => {

    toast.success(text, {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false
    });

}

export const toastError = ( text ) => {

    toast.error(text, {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false
    });

}

export const toastWarning = ( text ) => {

    toast.warning(text, {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false
    });

}

export const toastInfo = ( text ) => {

    toast.info(text, {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false
    });

}

export const Swal = async ( title, text, type, showCancelButton, confirmButtonText, input = null, inputAttributes = { } ) => {

    return withReactContent(SweetAlert).fire({

        title,
        text,
        type,
        showCancelButton,
        confirmButtonText,
        input,
        inputAttributes,
        cancelButtonText: 'Cancel',
        animation: false,
        customClass: {
            popup: 'animated zoomIn faster'
        },

        preConfirm: (data) => {
            
            if( input !== null && !data.length ) {

                jQuery('.swal2-input').addClass('important')
                jQuery('.swal2-validation-message').addClass('animated fadeIn')
                withReactContent(SweetAlert).showValidationMessage(inputAttributes.error)

                return false

            }

            return
        },

        onOpen: function(modal) {

            setTimeout(function() {
                modal.className += ' bshadow';
            }, 50)

        },

        onClose: function(modal) {

            modal.className = modal.className.replace('swal2-noanimation', '')
            modal.className = modal.className.replace('animated', '')
            modal.className = modal.className.replace('zoomIn', '')
            modal.className = modal.className.replace('faster', '')
            modal.className = modal.className.replace('bshadow', '')

        }

    })

}