import React from 'react'
import axios from 'axios'
import qs from 'qs'
import moment from 'moment'

import { withRouter } from 'react-router-dom'
import { core } from '../../config.js'

import Sidebar from './sidebar.jsx'

class App extends React.Component {

	constructor( ) {

		super( )
		this.state = {

			data: null,
			businesses: [ ],

			loading: true,
			secret: localStorage.getItem('authkey')

		}

	}

	componentDidMount( ) {

		axios.post(core, qs.stringify({

            adminBusiness: true,
            business: this.props.match.params.business,
            secret: this.state.secret

        })).then((response) => {

            if( response.data[0] === 'success' ) {

            	this.setState({ data: response.data[1] }, ( ) => {

            		axios.post(core, qs.stringify({

			            adminBusinessLocations: true,
			            business: this.props.match.params.business,
			            secret: this.state.secret

			        })).then((response) => {

			            if( response.data[0] === 'success' )
			            	this.setState({ businesses: response.data[1], loading: false })

			            else
			            	this.props.history.push('/404')

			        }).catch((error) => console.log(error))

            	})

            }

            else
            	this.props.history.push('/404')

        }).catch((error) => console.log(error))

	}

	render( ) {

		if( this.state.loading ) return null

		return (
			<div className="business">
				<Sidebar />

				<div className="page-content admin-company-details">
					<div className="page-breadcrumb" onClick={( ) => this.props.history.push('/admin/businesses')}>
						<img src="/landing/assets/images/back.svg" alt="" />
						<span>Liste over virksomheder</span>
					</div>

					<div className="row">
						<div className="col-3" style={{ marginTop: 10 }}>
							<div className="custom-card">
								<img src={this.state.data.b_logo} alt="" />
								<div className="title">{this.state.data.b_name}</div>

								<ul>
									<li>
										<span>Adresse</span>
										<span>{this.state.data.b_address}</span>
									</li>
									<li>
										<span>By</span>
										<span>{this.state.data.b_city}</span>
									</li>
									<li>
										<span>Postnummer</span>
										<span>{this.state.data.b_zipcode}</span>
									</li>
									<li>
										<span>CVR</span>
										<span>{this.state.data.b_vat}</span>
									</li>
									<li>
										<span>Telefon</span>
										<span>{this.state.data.b_phone}</span>
									</li>
									<li>
										<span>Hjemmeside</span>
										<span>{this.state.data.b_website}</span>
									</li>
								</ul>

								<div className="contact-person">
									<span>Kontaktperson</span>
									<span>{this.state.data.b_contact}</span>
									<span>{this.state.data.b_contact_position}</span>
								</div>
							</div>
						</div>
						<div className="col-9">
							<table className="locations-table">
							    <thead>
							        <tr>
							            <th>ID</th>
							            <th>Placeringer</th>
							            <th>Kategori</th>
							            <th>Underkategori</th>
							            <th>By</th>
							            <th>Tilføjet den</th>
							            <th></th>
							        </tr>
							    </thead>
							    <tbody>
							    	{this.state.businesses.map((node, i) => (

							    		<tr key={`business${i}`}>
								            <td>{i + 1}</td>
								            <td>
								            	<span>{node.l_name}</span>
								            	<span>{node.l_address}</span>
								            </td>
								            <td>
								            	<span>{node.c_name}</span>
								            </td>
								            <td>
								            	<span>{node.sc_name}</span>
								            </td>
								            <td>
								            	<span>{node.l_city}</span>
								            </td>
								            <td>
								            	<span>{moment(node.l_date).format('MMM DD, YYYY')}</span>
								            </td>
								            <td>
								            	<span className="btn btn-primary" onClick={( ) => this.props.history.push(`/admin/view-location/${node.l_id}`)}>Se detaljer</span>
								            </td>
								        </tr>

							    	))}
							    </tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
		)

	}
}

export default withRouter(App)
