import React from 'react'

import axios from 'axios'

import { withRouter } from 'react-router-dom'
import { core } from '../../config.js'

import Sidebar from './sidebar.jsx'

class App extends React.Component {

	constructor() {

		super( )
		this.state = {

			data: [ ],

			secret: localStorage.getItem('authkey')

		}

	}

	componentDidMount( ) {

		axios.get(`${core}?getAdminBusinesses=${this.state.secret}`).then((response) => {

			if( response.data[0] === 'success' )
				this.setState({ data: response.data[1] })

		}).catch((error) => console.log(error))

	}

	render( ) {

		return (
			<div className="business">
				<Sidebar />

				<div className="page-content admin-businesses">
					<div className="page-title">Virksomheder</div>

					<table className="businesses-table-admin">
					    <thead>
					        <tr>
					            <th>ID</th>
					            <th>Logo</th>
					            <th>Navn + Adresse</th>
					            <th>By</th>
					            <th>Postnummer</th>
					            <th>CVR</th>
					            <th>Telefon</th>
					            <th>Abonnement</th>
					            <th>Billed</th>
					            <th>Udløber</th>
					            <th></th>
					        </tr>
					    </thead>
					    <tbody>
					    	{this.state.data.map((node, i) => (
						        <tr key={`business${i}`}>
						            <td>{i + 1}</td>
						            <td><img src={node.b_logo} alt="" /></td>
						            <td>
						            	<span>{node.b_name}</span>
						            	<span>{node.b_address}</span>
						            </td>
						            <td>
						            	<span>{node.b_city}</span>
						            </td>
						            <td>
						            	<span>{node.b_zipcode}</span>
						            </td>
						            <td>
						            	<span>{node.b_vat}</span>
						            </td>
						            <td>
						            	<span>{node.b_phone}</span>
						            </td>
						            <td>
						            	<span>Premium</span>
						            </td>
						            <td>
						            	<span>Yearly</span>
						            </td>
						            <td>
						            	<span>20/06/2021</span>
						            </td>
						            <td>
						            	<span className="btn btn-primary" onClick={( ) => this.props.history.push(`/admin/view-business/${node.b_id}`)}>Se detaljer</span>
						            </td>
						        </tr>
					        ))}
					    </tbody>
					</table>
				</div>
			</div>
		)

	}

}

export default withRouter(App)
